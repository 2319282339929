import { Button } from "../ui/button.jsx";
import { Card, CardContent } from "../ui/card.jsx";
import ProgressBar from "./ProgressBar";

const InterfaceHeader = ({
  saving,
  sendingAnswers,
  onSaveAndExit,
  currentIndex,
  numberOfQuestions,
  error,
}) => {
  return (
    <Card className="mb-6 bg-white shadow-sm">
      <CardContent className="p-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold text-gray-900">
            Assignment Progress
          </h2>
          <div className="space-x-2 flex items-center">
            {error && <p className="text-red-600">{error}</p>}
            <Button
              variant="outline"
              onClick={onSaveAndExit}
              disabled={saving || sendingAnswers}
              className="text-gray-700 hover:bg-gray-50 focus:ring-primary"
            >
              {saving ? "Saving and exiting..." : "Save and Exit"}
            </Button>
          </div>
        </div>
        <ProgressBar
          currentIndex={currentIndex}
          numberOfQuestions={numberOfQuestions}
        />
      </CardContent>
    </Card>
  );
};

export default InterfaceHeader;
