import { AssignmentToolProvider } from "./useAssignmentTool";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import LoginPage from "./Login/LoginPage.js";
import CreateAccountPage from "./Login/CreateAccountPage.js";
import { UserProvider } from "./contexts/userContext.js";
import Homepage from "./Homepage.js";
import AssignmentInterfacePage from "./AssignmentInterfacePage.js";
import ProfilePage from "./ProfilePage";
import { ChatProvider } from "./contexts/chatContext";
import ErrorBoundary from "./contexts/errorBoundary";
import UnauthorizedPage from "./UnauthorizedPage";
import ProtectedRoute from "./ProtectedRoute";
import PrivacyPolicy from "./PrivacyPolicy";
import PageTracker from "./PageTracker";

function App() {
  return (
    <Router>
      <ErrorBoundary>
        <UserProvider>
          <AssignmentToolProvider>
            <ChatProvider>
              <PageTracker />
              <Routes>
                <Route path="/" element={<LoginPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/create-account" element={<CreateAccountPage />} />
                <Route
                  path="/homepage"
                  element={
                    <ProtectedRoute
                      accessRule={(user) => {
                        return user.uid !== null;
                      }}
                    >
                      <Homepage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/assignment/:assignmentId"
                  element={
                    <ProtectedRoute
                      accessRule={({ assignments }, params) => {
                        const assignmentIds = assignments?.map(
                          (assignment) => assignment.id
                        );
                        return assignmentIds.some(
                          (id) => id === params.assignmentId
                        );
                      }}
                    >
                      <AssignmentInterfacePage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/profile/:userId"
                  element={
                    <ProtectedRoute
                      accessRule={(user, params) => {
                        return user.uid === params.userId;
                      }}
                    >
                      <ProfilePage />
                    </ProtectedRoute>
                  }
                ></Route>

                <Route
                  path="/privacy-policy"
                  element={<PrivacyPolicy />}
                ></Route>

                <Route path="/unauthorized" element={<UnauthorizedPage />} />

                <Route
                  path="*"
                  element={<Navigate to="/unauthorized" replace />}
                />
              </Routes>
            </ChatProvider>
          </AssignmentToolProvider>
        </UserProvider>
      </ErrorBoundary>
    </Router>
  );
}

export default App;
