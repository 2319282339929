import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { useToast } from "./ui/use-toast";
import { storage } from "./firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const isValidEmail = (email) => {
  return email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const allowedTags = {
  // Text formatting
  strong: {
    component: "strong",
    className: "font-semibold",
  },

  // Headings
  h2: {
    component: "h2",
    className: "text-lg font-semibold mb-3",
  },
  h3: {
    component: "h3",
    className: "text-base font-semibold mb-2",
  },
  h4: {
    component: "h4",
    className: "text-base font-semibold mb-2",
  },

  // Lists
  ol: {
    component: "ol",
    className: "list-decimal pl-6 mb-3 space-y-1",
  },
  ul: {
    component: "ul",
    className: "list-disc pl-6 mb-3 space-y-1",
  },
  li: {
    component: "li",
    className: "pl-1 leading-normal",
  },

  // Paragraphs
  p: {
    component: "p",
    className: "mb-3 last:mb-0",
  },
};

export const formatMessage = (content) => {
  if (!content) return null;

  const tagRegex = /<\/?([a-z][a-z0-9]*)\b[^>]*>|([^<>]+)/gi;
  const parts = content.match(tagRegex) || [];
  const result = [];
  let stack = [];
  let currentText = "";

  const processText = (text, tags = []) => {
    if (!text.trim()) return null;

    return tags.reduceRight((wrapped, tag) => {
      const tagInfo = allowedTags[tag.name];
      if (!tagInfo) return wrapped;

      const Element = tagInfo.component;
      const props = {
        className: tagInfo.className,
        key: `${tag.name}-${result.length}`,
      };

      // For ordered lists, ensure we're not disrupting the natural ordering
      if (tag.name === "ol") {
        props.role = "list";
      }

      // For list items, we might need special handling for nested lists
      if (tag.name === "li") {
        const parentType = stack.find(
          (t) => t.name === "ol" || t.name === "ul"
        )?.name;
        if (parentType === "ol") {
          props.value = tag.attributes?.value; // Preserve explicit numbering if specified
        }
      }

      return <Element {...props}>{wrapped}</Element>;
    }, <span key={`text-${result.length}`}>{text}</span>);
  };

  parts.forEach((part) => {
    if (part.startsWith("</")) {
      // Closing tag
      const tagName = part.slice(2, -1).toLowerCase();
      if (allowedTags[tagName]) {
        if (currentText) {
          result.push(processText(currentText, stack));
          currentText = "";
        }
        stack = stack.filter((t) => t.name !== tagName);
      }
    } else if (part.startsWith("<")) {
      // Opening tag
      const match = part.match(/<([a-z][a-z0-9]*)\b[^>]*>/i);
      if (match) {
        const tagName = match[1].toLowerCase();
        if (allowedTags[tagName]) {
          if (currentText) {
            result.push(processText(currentText, stack));
            currentText = "";
          }

          // Extract any attributes
          const attributes = {};
          const attrMatch = part.match(/(\w+)="([^"]*)"/g);
          if (attrMatch) {
            attrMatch.forEach((attr) => {
              const [key, value] = attr.split("=");
              attributes[key] = value.replace(/"/g, "");
            });
          }

          stack.push({ name: tagName, attributes });
        }
      }
    } else {
      // Text content
      currentText += part;
    }
  });

  // Process any remaining text
  if (currentText) {
    result.push(processText(currentText, stack));
  }

  return result;
};

export const formatFileSize = (bytes) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};

export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(",")[1]);
    reader.onerror = (error) => reject(error);
  });
};

export const useThrowToastError = () => {
  const { toast } = useToast();
  return (error) => {
    toast({
      variant: "destructive",
      title: "Error",
      description: `There was a server error: ${error} Please try again.`,
    });
  };
};

export const isLightColor = (hexColor) => {
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness > 128;
};

export const getLighterShade = (hex, percent) => {
  const num = parseInt(hex.replace("#", ""), 16);
  const amt = Math.round(2.55 * percent);
  const R = (num >> 16) + amt;
  const G = ((num >> 8) & 0x00ff) + amt;
  const B = (num & 0x0000ff) + amt;
  return `#${(
    (1 << 24) |
    ((R < 255 ? (R < 1 ? 0 : R) : 255) << 16) |
    ((G < 255 ? (G < 1 ? 0 : G) : 255) << 8) |
    (B < 255 ? (B < 1 ? 0 : B) : 255)
  )
    .toString(16)
    .slice(1)}`;
};

export const getArrayCharacterCount = (array) => {
  return array.reduce((acc, item) => acc + item.length, 0);
};

export function haveSameElements(arr1, arr2) {
  return (
    arr1.length === arr2.length &&
    arr1.every((item) => arr2.includes(item)) &&
    arr2.every((item) => arr1.includes(item))
  );
}

export const translateErrorMessage = (error) => {
  if (error.code) {
    switch (error.code) {
      case "auth/invalid-email":
        return "Invalid email address. Please try again.";
      case "auth/invalid-credential":
        return "Incorrect username or password. Please try again.";
      case "auth/user-disabled":
        return "This account has been disabled. Please contact support.";
      case "auth/user-not-found":
        return "No account found with this email. Please create an account.";
      case "auth/wrong-password":
        return "Incorrect password. Please try again.";
      case "auth/too-many-requests":
        return "Too many failed login attempts. Please try again later or reset your password.";
      case "auth/network-request-failed":
        return "Network error. Please check your internet connection and try again.";
      case "NO_ACCOUNT":
        return "No account found. Please create an account first.";
      case "ERR_NETWORK":
        return "Could not connect to servers. Please try again later.";
      default:
        return "An error occurred during login. Please try again.";
    }
  } else if (error.response) {
    return "Error fetching user data. Please try again.";
  } else {
    return "An unexpected error occurred. Please try again.";
  }
};

export const uploadFile = async (file) => {
  try {
    // Create a unique filename to prevent collisions
    const timestamp = Date.now();
    const uniqueFilename = `${timestamp}-${file.name}`;

    // Create a reference to the file location in Firebase Storage
    const storageRef = ref(storage, `assignments/${uniqueFilename}`);

    // Upload the file
    const snapshot = await uploadBytes(storageRef, file);

    // Get the download URL
    const downloadURL = await getDownloadURL(snapshot.ref);

    return downloadURL;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw new Error("Failed to upload file");
  }
};
