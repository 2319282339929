import api from "../api";

const assignmentPreviouslySaved = async (submissionId) => {
  try {
    const response = await api.get(`/submissions/${submissionId}/exists`);
    return response.data.exists;
  } catch (error) {
    console.error(error);
  }
};

const fetchAnswers = async (userId, assignmentId) => {
  const submissionId = `${userId}_${assignmentId}`;

  try {
    const existenceResponse = await api.get(
      `/submissions/${submissionId}/exists`
    );
    if (existenceResponse.data.exists) {
      const response = await api.get(`/submissions/${submissionId}`);

      return response.data.answers;
    } else {
      return {};
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const fetchQuestions = async (assignmentId) => {
  try {
    const response = await api.get(`/assignments/${assignmentId}`);
    const questionData = await response.data.questions;
    return questionData;
  } catch (error) {
    console.error(error);
  }
};

const saveProgress = async (userId, assignmentId, answers) => {
  const submitTime = Date.now();
  const submissionId = `${userId}_${assignmentId}`;
  const saved = await assignmentPreviouslySaved(submissionId);
  const integrityReport = { totalScore: "", questionScores: {} };
  try {
    if (saved) {
      await api.put(`/submissions/${submissionId}`, {
        assignmentId,
        answers,
        submitTime,
        submitted: false,
        integrityReport,
      });
    } else {
      await api.post(`/submissions/`, {
        userId,
        assignmentId,
        answers,
        submitTime,
        submitted: false,
        integrityReport,
      });
    }
  } catch (error) {
    throw new Error("Error saving assignment");
  }
};

export const InterfaceService = {
  assignmentPreviouslySaved,
  fetchAnswers,
  fetchQuestions,
  saveProgress,
};
