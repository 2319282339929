import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useEffect,
} from "react";
import useAuth from "../hooks/useAuth";
import useUserInfo from "../hooks/useUserInfo";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const auth = useAuth();
  const modifiedUser = useMemo(
    () => (auth.user ? { ...auth.user, paid: true } : auth.user),
    [auth.user]
  );

  const userInfo = useUserInfo(modifiedUser);

  const logout = useCallback(async () => {
    await auth.logout();
    userInfo.resetUserInfo();
  }, [auth.authLogout, userInfo.resetUserInfo]);

  useEffect(() => {
    if (!auth.user) return;

    let inactivityTimer;

    const resetTimer = () => {
      if (inactivityTimer) clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(async () => {
        await logout();
      }, 60 * 60 * 1000);
    };

    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keypress", resetTimer);
    window.addEventListener("click", resetTimer);

    resetTimer();

    return () => {
      if (inactivityTimer) clearTimeout(inactivityTimer);
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keypress", resetTimer);
      window.removeEventListener("click", resetTimer);
    };
  }, [auth.user, logout]);

  const value = useMemo(
    () => ({
      user: modifiedUser,
      assignments: userInfo.assignments,
      submissions: userInfo.submissions,
      updateAssignments: userInfo.updateAssignments,
      removeAssignment: userInfo.removeAssignment,
      updateSubmissions: userInfo.updateSubmissions,
      loading: userInfo.loading || !modifiedUser,
      error: userInfo.error,
      login: auth.login,
      signup: auth.signup,
      logout,
      googleSignup: auth.googleSignup,
      googleLogin: auth.googleLogin,
      refreshUserInfo: userInfo.refreshUserInfo,
    }),
    [modifiedUser, userInfo, auth, logout]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
