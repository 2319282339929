import { Textarea } from "./ui/textarea.jsx";

const ChoiceInput = ({ choices, onChange, index }) => {
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  const handleChange = (e) => {
    const updatedChoices = [...choices];
    updatedChoices[index] = e.target.value;
    onChange(updatedChoices);
  };

  return (
    <div>
      <Textarea
        type="text"
        value={choices[index]}
        onChange={handleChange}
        placeholder={`Choice ${alphabet[index]}`}
        className="w-full min-h-[40px] resize-none"
      />
    </div>
  );
};

export default ChoiceInput;
