import { Trash2 } from "lucide-react";
import { Button } from "./ui/button.jsx";
import { Card, CardContent } from "./ui/card.jsx";
import ChoiceInput from "./ChoiceInput";

const Choice = ({ choices, onChoicesChange, index }) => {
  const handleDelete = () => {
    const updatedChoices = choices.filter((_, i) => i !== index);
    onChoicesChange(updatedChoices);
  };

  return (
    <Card className="mb-4">
      <CardContent className="p-4">
        <div className="flex items-center w-full">
          <div className="flex-grow mr-4">
            <ChoiceInput
              choices={choices}
              onChange={onChoicesChange}
              index={index}
            />
          </div>
          <div className="flex items-center">
            <Button variant="destructive" size="icon" onClick={handleDelete}>
              <Trash2 className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default Choice;
