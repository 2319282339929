import { Upload, X, FileIcon } from "lucide-react";
import { useRef } from "react";
import { Alert, AlertDescription } from "./ui/alert.jsx";
import { Input } from "./ui/input.jsx";
import { Label } from "./ui/label.jsx";
import { Button } from "./ui/button.jsx";
import React from "react";

const ALLOWED_FILE_TYPES = {
  "application/pdf": ".pdf",
  "image/png": ".png",
  "image/jpeg": ".jpg",
  "image/jpg": ".jpg",
};

const FileUploader = React.forwardRef(({ onFileChange, error }, ref) => {
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = React.useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type in ALLOWED_FILE_TYPES) {
      setSelectedFile(file);
      onFileChange(file);
    } else {
      clearFileInput();
    }
  };

  const clearFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setSelectedFile(null);
    onFileChange(null);
  };

  React.useImperativeHandle(ref, () => ({
    clearFileInput,
  }));

  return (
    <div className="space-y-4">
      <div className="relative group">
        {!selectedFile ? (
          <>
            <Input
              id="fileInput"
              ref={fileInputRef}
              type="file"
              onChange={handleFileChange}
              accept={Object.values(ALLOWED_FILE_TYPES).join(",")}
              className="h-32 cursor-pointer opacity-0 absolute inset-0 w-full z-10"
            />
            <div className="h-32 border-2 border-dashed border-primary/20 group-hover:border-primary/40 rounded-lg transition-colors duration-200 flex flex-col items-center justify-center text-center p-4">
              <Upload className="w-8 h-8 text-primary/40 group-hover:text-primary/60 mb-2" />
              <Label
                htmlFor="fileInput"
                className="font-medium text-base cursor-pointer"
              >
                Drop your file here or click to browse
              </Label>
              <p className="text-sm text-muted-foreground mt-1">
                Supports PDF, PNG, and JPG
              </p>
            </div>
          </>
        ) : (
          <div className="h-32 border-2 border-primary rounded-lg transition-colors duration-200 flex items-center justify-between p-4 bg-primary/5">
            <div className="flex items-center space-x-4">
              <FileIcon className="w-8 h-8 text-primary" />
              <div className="flex flex-col">
                <span className="font-medium">{selectedFile.name}</span>
                <span className="text-sm text-muted-foreground">
                  {(selectedFile.size / 1024 / 1024).toFixed(2)} MB
                </span>
              </div>
            </div>
            <Button
              variant="ghost"
              size="icon"
              className="text-muted-foreground hover:text-foreground"
              onClick={clearFileInput}
            >
              <X className="w-4 h-4" />
            </Button>
          </div>
        )}
      </div>

      {error && (
        <Alert variant="destructive" className="mt-2">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
    </div>
  );
});

FileUploader.displayName = "FileUploader";

export default FileUploader;
