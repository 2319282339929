import { useEffect, useRef, useState } from "react";
import api from "../api";
import { useUser } from "../contexts/userContext";
import ChatInput from "./ChatInput";
import ChatMessage from "./ChatMessage";
import { getInstructions } from "./InstructionFormatter.js";
import TypingIndicator from "./TypingIndicator";
import { FREE_CHAT_LIMIT } from "../PricesAndLimits";
import { useChatMessages } from "../contexts/chatContext";

const ChatComponent = ({ chatLogId, question, exiting, fileUrl }) => {
  const [message, setMessage] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [chatCount, setChatCount] = useState(0);
  const [chatDisabled, setChatDisabled] = useState(false);
  const chatContainerRef = useRef(null);
  const shouldAutoScrollRef = useRef(true);
  const isInitialLoadRef = useRef(true);

  const { user, loading: userLoading } = useUser();
  const paid = user?.paid || false;
  const studentFirstName = userLoading ? "" : user?.firstName;
  const [chatsRemaining, setChatsRemaining] = useState(FREE_CHAT_LIMIT);

  const {
    chatLogs,
    updateChatLog,
    loading,
    saveLogs,
    initializeChatLog,
    handleError,
    error,
  } = useChatMessages();

  useEffect(() => {
    initializeChatLog(chatLogId);
  }, [chatLogId]);

  const saveChatHistory = async () => {
    try {
      await saveLogs();
    } catch (error) {
      handleError("Failed to save chat history");
    }
  };

  useEffect(() => {
    if (exiting) {
      saveChatHistory();
    }
  }, [exiting]);

  useEffect(() => {
    if (chatCount >= 2) {
      saveChatHistory();
      setChatCount(0);
    }
  }, [chatCount]);

  useEffect(() => {
    if (chatLogs[question.id]?.length > 0) {
      const usedChats = Math.ceil(chatLogs[question.id].length / 2);
      const remaining = Math.max(0, FREE_CHAT_LIMIT - usedChats);
      setChatsRemaining(remaining);
    } else {
      setChatsRemaining(FREE_CHAT_LIMIT);
    }
  }, [chatLogs, question.id]);

  useEffect(() => {
    const hasExceededLimit = chatsRemaining <= 0;
    setChatDisabled(hasExceededLimit && !paid);
  }, [chatsRemaining, paid]);

  const handleScroll = () => {
    if (chatContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        chatContainerRef.current;
      const isNearBottom = scrollHeight - scrollTop - clientHeight < 100;
      shouldAutoScrollRef.current = isNearBottom;
    }
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      const container = chatContainerRef.current;
      container.scrollTo({
        top: container.scrollHeight,
        behavior: isInitialLoadRef.current ? "auto" : "smooth",
      });
    }
  };

  useEffect(() => {
    const container = chatContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, []);

  useEffect(() => {
    const shouldHandle =
      chatLogs[question.id]?.length > 0 &&
      (isInitialLoadRef.current || shouldAutoScrollRef.current);

    if (shouldHandle) {
      scrollToBottom();
    }
    isInitialLoadRef.current = false;
  }, [chatLogs[question.id]?.length, isTyping]);

  const handleChat = async () => {
    if (!message.trim() || chatDisabled) return;

    try {
      updateChatLog(question.id, "user", message);
      setMessage("");
      setIsTyping(true);
      shouldAutoScrollRef.current = true;

      const instructions = getInstructions(studentFirstName, question);
      const response = await api.post("/chat", {
        chatLogId,
        questionId: question.id,
        message,
        instructions,
        // fileUrl,
      });

      const reply = response.data.reply;
      updateChatLog(question.id, "bot", reply);
      setChatCount(chatCount + 2);
    } catch (error) {
      handleError("Something went wrong.");
    } finally {
      setIsTyping(false);
    }
  };

  if (loading) {
    return (
      <div className="h-full flex items-center justify-center">
        <p className="text-gray-500">Loading...</p>
      </div>
    );
  }

  return (
    <div className="h-full flex flex-col">
      <div
        ref={chatContainerRef}
        className="h-[85%] overflow-y-auto p-4 space-y-4 bg-gray-50"
        style={{
          overscrollBehavior: "contain",
          willChange: "transform",
        }}
      >
        {chatLogs[question.id]?.map((chat, index) => (
          <ChatMessage
            key={`${chat.timestamp}-${index}`}
            type={chat.type}
            content={chat.chat}
            timestamp={chat.timestamp}
          />
        ))}
        {isTyping && <TypingIndicator />}
      </div>
      <div className="h-[15%] border-t">
        <ChatInput
          message={message}
          setMessage={setMessage}
          handleChat={handleChat}
          chatDisabled={chatDisabled}
        />
      </div>
      {chatDisabled && (
        <p className="text-red-500 text-center m-2">
          Question chat limit reached ({FREE_CHAT_LIMIT} chats). Please see your
          instructor to upgrade.
        </p>
      )}
      {!paid && !chatDisabled && (
        <p className="text-gray-600 text-center m-2">
          Chats remaining: {chatsRemaining}
        </p>
      )}
      {error && <p className="text-red-500 text-center m-2">{error}</p>}
    </div>
  );
};

export default ChatComponent;
