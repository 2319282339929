import { Button } from "../ui/button.jsx";
import { Card, CardContent } from "../ui/card.jsx";

const QuestionNavigation = ({
  currentQuestionIndex,
  questionCount,
  onNext,
  onPrevious,
}) => {
  const totalQuestions = questionCount || 0;
  const isFirstQuestion = currentQuestionIndex === 0;
  const isLastQuestion = currentQuestionIndex === totalQuestions - 1;

  return (
    <Card className="w-full">
      <CardContent className="flex justify-between items-center p-4">
        <Button
          onClick={onPrevious}
          variant="outline"
          size="sm"
          disabled={isFirstQuestion}
        >
          Previous
        </Button>
        <span className="text-sm text-gray-500">
          Question {currentQuestionIndex + 1} of {totalQuestions}
        </span>
        <Button
          onClick={onNext}
          variant="default"
          size="sm"
          disabled={isLastQuestion}
        >
          Next
        </Button>
      </CardContent>
    </Card>
  );
};

export default QuestionNavigation;
