import { useNavigate } from "react-router-dom";
import { useUser } from "../contexts/userContext";
import { Button } from "../ui/button.jsx";
import { useState } from "react";

const LogoutButton = ({ className = null }) => {
  const navigate = useNavigate();
  const { logout } = useUser();
  const [loading, setLoading] = useState(false);

  const handleLogout = async () => {
    setLoading(true);
    try {
      await logout();
      navigate("/login", { replace: true, state: { isLogout: true } });
    } catch (error) {
      console.error("Logout failed:", error);
    } finally {
      setLoading(true);
    }
  };

  return (
    <Button
      onClick={handleLogout}
      variant="outline"
      disabled={loading}
      className={className}
    >
      {loading ? "Logging out..." : "Logout"}
    </Button>
  );
};

export default LogoutButton;
