import { Button } from "./ui/button.jsx";
import Choice from "./Choice";
import Text from "./Text";
import Type from "./Type";
import { useAssignmentTool } from "./useAssignmentTool";

const Question = ({ index }) => {
  const { questions, updateQuestion } = useAssignmentTool();
  const question = questions[index];

  if (!question) {
    return null;
  }

  const onTextChange = (e) => {
    updateQuestion(index, { ...question, text: e.target.value });
  };

  const onTypeChange = (newType) => {
    updateQuestion(index, { ...question, type: newType });
  };

  const onChoicesChange = (newChoices) => {
    updateQuestion(index, { ...question, choices: newChoices || [] });
  };

  const handleAddChoice = () => {
    const currentChoices = question.choices || [];
    const updatedChoices = [...currentChoices, []];
    updateQuestion(index, {
      ...question,
      choices: updatedChoices,
    });
  };

  return (
    <div>
      <div className="flex items-center mb-2">
        <h2 className="mr-4">Question {index + 1}</h2>
      </div>

      <Text onChange={onTextChange} text={question.text || ""} />

      <Type onChange={onTypeChange} type={question.type || ""} />

      {(question.type === "multiple-choice" ||
        question.type === "select-all") && (
        <>
          {(question.choices || []).length < 6 && (
            <Button className="mb-4" onClick={handleAddChoice}>
              Add Choice
            </Button>
          )}
          {(question.choices || []).map((choice, choiceIndex) => (
            <div key={choiceIndex}>
              <Choice
                choices={question.choices || []}
                onChoicesChange={onChoicesChange}
                index={choiceIndex}
              />
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default Question;
