import { ChevronLeft, Lock } from "lucide-react";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card.jsx";
import { useNavigate } from "react-router-dom";
import { Button } from "./ui/button.jsx";
import { useEffect } from "react";

const Section = ({ title, children }) => (
  <div className="mb-8">
    <h2 className="text-xl font-semibold mb-4 text-primary">{title}</h2>
    <div className="space-y-4">{children}</div>
  </div>
);

const ListSection = ({ title, items }) => (
  <Section title={title}>
    <ul className="list-disc pl-6 space-y-2">
      {items.map((item, index) => (
        <li key={index} className="text-gray-700">
          {item}
        </li>
      ))}
    </ul>
  </Section>
);

const ContactInfo = () => (
  <div className="bg-primary/5 p-6 rounded-lg">
    <h3 className="font-semibold mb-3">Contact Information</h3>
    <div className="space-y-1 text-gray-700">
      <p>Founder/CEO</p>
      <p>Aila Education LLC</p>
      <a
        href="mailto:Jake@AilaEducation.com"
        className="text-primary hover:underline"
      >
        Jake@AilaEducation.com
      </a>
    </div>
  </div>
);

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const automatedDataItems = [
    "Log data (IP addresses, browser type, pages accessed)",
    "Device information",
    "Platform usage patterns",
    "Performance metrics",
  ];

  const userRightsItems = [
    "Accessing your personal data",
    "Requesting corrections to inaccurate information",
    "Requesting data deletion",
    "Withdrawing previous consent",
    "Receiving a copy of your data in a portable format",
  ];

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4">
      <div className="max-w-4xl mx-auto flex">
        <Button
          className="bg-transparent text-primary hover:bg-primary/15"
          onClick={() => navigate(-1)}
        >
          <ChevronLeft />{" "}
        </Button>
        <Card className="shadow-lg border-2 border-primary/10">
          <CardHeader className="border-b border-primary/10">
            <CardTitle className="flex items-center gap-2 text-2xl">
              <Lock className="h-6 w-6 text-primary" />
              Privacy Policy
            </CardTitle>
            <p className="text-sm text-gray-500">
              Last Updated: January 26, 2025
            </p>
          </CardHeader>

          <CardContent className="p-6 space-y-8">
            <Section title="">
              <p className="text-gray-700">
                Aila Education LLC ("Aila," "we," "us," or "our") is committed
                to protecting your privacy and ensuring the security of your
                personal information. This Privacy Policy explains our practices
                regarding the collection, use, and safeguarding of your data
                when you use our AI tutoring platform and related services
                (collectively, the "Services").
              </p>
            </Section>

            <Section title="Information Collection and Use">
              <p className="text-gray-700">
                When you create an account with Aila Education LLC, we collect
                and store certain personal information in our secure, encrypted
                database. This information includes your first name, last name,
                and email address. If you choose to authenticate through
                Google's login services, we collect the same data elements from
                your Google account with your permission.
              </p>

              <div className="mt-4 space-y-4">
                <div>
                  <strong className="text-primary">First Name: </strong>
                  <span className="text-gray-700">
                    We use your first name to personalize your educational
                    experience, particularly in interactions with our AI tutor,
                    Sparky. This personalization helps create an engaging and
                    effective learning environment.
                  </span>
                </div>

                <div>
                  <strong className="text-primary">Last Name: </strong>
                  <span className="text-gray-700">
                    We utilize your last name for account identification, course
                    enrollment tracking, and billing purposes. To maintain
                    privacy, your last name is not shared with our AI tutor.
                  </span>
                </div>

                <div>
                  <strong className="text-primary">Email Address: </strong>
                  <span className="text-gray-700">
                    Your email address enables essential account administration,
                    including password reset functionality, subscription
                    management, and important account notifications.
                  </span>
                </div>
              </div>
            </Section>

            <ListSection
              title="Automated Data Collection"
              items={automatedDataItems}
            />

            <Section title="Data Security and Storage">
              <p className="text-gray-700">
                We implement industry-standard security measures to protect your
                personal information. All data is encrypted both in transit and
                at rest using robust encryption protocols. We regularly review
                and update our security practices to maintain data protection.
              </p>
            </Section>

            <ListSection
              title="User Rights and Choices"
              items={userRightsItems}
            />

            <Section title="Children's Privacy">
              <p className="text-gray-700">
                Our Services are designed for users aged 13 and older. We do not
                knowingly collect personal information from children under 13.
                If we learn that we have inadvertently collected such
                information, we will take steps to delete it promptly.
              </p>
            </Section>

            <Section title="Contact Information">
              <ContactInfo />
            </Section>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
