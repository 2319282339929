import React, { createContext, useContext, useState } from "react";

const AssignmentToolContext = createContext();

export const AssignmentToolProvider = ({ children }) => {
  const [questions, setQuestions] = useState([]);
  const [file, setFile] = useState(null);
  const [successfulUpload, setSuccessfulUpload] = useState(false);
  const [title, setTitle] = useState("");

  const handleGeneration = (newQuestions, uploadedFile) => {
    setQuestions(newQuestions);
    setFile(uploadedFile);
    setSuccessfulUpload(true);
  };

  const addQuestion = () => {
    setQuestions([
      ...questions,
      {
        text: "",
        type: "",
        choices: [],
      },
    ]);
  };

  const deleteQuestion = (indexToDelete) => {
    setQuestions(questions.filter((_, index) => index !== indexToDelete));
  };

  const updateQuestion = (index, updatedQuestion) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index] = updatedQuestion;
    setQuestions(updatedQuestions);
  };

  const clearFields = () => {
    setQuestions([]);
    setFile(null);
    setTitle("");
    setSuccessfulUpload(false);
  };

  const value = {
    questions,
    setQuestions,
    file,
    setFile,
    successfulUpload,
    setSuccessfulUpload,
    title,
    setTitle,
    handleGeneration,
    addQuestion,
    deleteQuestion,
    updateQuestion,
    clearFields,
  };

  return (
    <AssignmentToolContext.Provider value={value}>
      {children}
    </AssignmentToolContext.Provider>
  );
};

export const useAssignmentTool = () => {
  const context = useContext(AssignmentToolContext);
  if (!context) {
    throw new Error(
      "useAssignmentTool must be used within an AssignmentToolProvider"
    );
  }
  return context;
};
