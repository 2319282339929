import { useState, useRef } from "react";
import { Volume2, Loader2 } from "lucide-react";
import { Button } from "./ui/button.jsx";
import api from "./api";
import { useChatMessages } from "./contexts/chatContext.js";

const AudioPlayer = ({ content, disabled }) => {
  const [loading, setLoading] = useState(false);
  const audioRef = useRef(null);
  const { soundPlaying, setSoundPlaying, handleError } = useChatMessages();
  const [thisInstancePlaying, setThisInstancePlaying] = useState(false);

  const handlePlaySound = async () => {
    try {
      if (!content) {
        handleError("No text content available to play");
        return;
      }

      setLoading(true);
      setSoundPlaying(true);

      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }

      const speechResponse = await api
        .post(
          "/voice/tts",
          { text: content },
          {
            responseType: "blob",
            headers: {
              Accept: "audio/mpeg",
              "Content-Type": "application/json",
            },
          }
        )
        .catch((error) => {
          error.response?.status === 429
            ? handleError("Too many requests. Please try again later.")
            : handleError("Failed to generate audio. Please try again.");
        });

      const audioBlob = new Blob([speechResponse.data], { type: "audio/mpeg" });
      const audioUrl = URL.createObjectURL(audioBlob);
      const audio = new Audio();

      audio.addEventListener("canplaythrough", () => {
        setThisInstancePlaying(true);
        setLoading(false);
        audio.play().catch((error) => {
          handleError(
            "Failed to play audio. Please check your audio settings."
          );
          setSoundPlaying(false);
          setLoading(false);
          setThisInstancePlaying(false);
        });
      });

      audio.addEventListener("ended", () => {
        setSoundPlaying(false);
        URL.revokeObjectURL(audioUrl);
        audioRef.current = null;
      });

      audio.addEventListener("error", (error) => {
        handleError("Audio playback error. Please try again.");
        setSoundPlaying(false);
        setLoading(false);
        setThisInstancePlaying(false);
        URL.revokeObjectURL(audioUrl);
        audioRef.current = null;
      });

      audio.src = audioUrl;
      audioRef.current = audio;
    } catch (error) {
      handleError(
        error.message || "An unexpected error occurred while playing audio"
      );
      setSoundPlaying(false);
      setLoading(false);
      setThisInstancePlaying(false);

      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    }
  };

  return (
    <Button
      onClick={handlePlaySound}
      className="rounded-md p-2 transition-colors duration-200 bg-transparent hover:bg-transparent text-primary/55 hover:text-primary disabled:text-gray-500"
      disabled={disabled || loading || soundPlaying}
    >
      {loading ? (
        <Loader2 className="w-4 h-4 animate-spin" />
      ) : (
        <Volume2
          className={`w-4 h-4 ${
            soundPlaying && thisInstancePlaying ? "text-red-600" : ""
          }`}
        />
      )}
    </Button>
  );
};

export default AudioPlayer;
