import React, { useState } from "react";
import {
  AlignCenter,
  AlignLeft,
  AlignRight,
  Indent,
  Outdent,
  Type,
} from "lucide-react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select.jsx";
import { Slider } from "../ui/slider.jsx";
import BaseEditor from "./BaseEditor";

const EssayEditor = (props) => {
  const [font, setFont] = useState("Arial");
  const [fontSize, setFontSize] = useState(16);
  const fonts = ["Arial", "Times New Roman", "Courier New", "Georgia"];

  const applyFont = () => {
    document.execCommand("fontName", false, font);
    props.onChange(document.querySelector("[contenteditable]").innerHTML);
  };

  const applyFontSize = () => {
    document.execCommand("fontSize", false, "7");
    const fontElements = document.getElementsByTagName("font");
    for (let i = 0; i < fontElements.length; i++) {
      if (fontElements[i].size === "7") {
        fontElements[i].removeAttribute("size");
        fontElements[i].style.fontSize = `${fontSize}px`;
      }
    }
    props.onChange(document.querySelector("[contenteditable]").innerHTML);
  };

  const applyHeader = (level) => {
    document.execCommand("formatBlock", false, `h${level}`);
    props.onChange(document.querySelector("[contenteditable]").innerHTML);
  };

  const additionalStyles = [
    { icon: AlignLeft, action: () => document.execCommand("justifyLeft") },
    { icon: AlignCenter, action: () => document.execCommand("justifyCenter") },
    { icon: AlignRight, action: () => document.execCommand("justifyRight") },
    { icon: Indent, action: () => document.execCommand("indent") },
    { icon: Outdent, action: () => document.execCommand("outdent") },
  ];

  return (
    <div className="max-w-4xl mx-auto">
      <div className="mb-4 flex flex-wrap items-center gap-4">
        <Select
          onValueChange={(value) => {
            setFont(value);
            applyFont();
          }}
          value={font}
        >
          <SelectTrigger className="w-40 bg-primary-50 border-primary-200">
            <SelectValue placeholder="Select font" />
          </SelectTrigger>
          <SelectContent>
            {fonts.map((f) => (
              <SelectItem key={f} value={f}>
                {f}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <div className="flex items-center space-x-2">
          <Type size={16} className="text-primary-600" />
          <Slider
            min={8}
            max={36}
            step={1}
            value={[fontSize]}
            onValueChange={(value) => {
              setFontSize(value[0]);
              applyFontSize();
            }}
            className="w-32"
          />
          <span className="text-sm text-primary-700">{fontSize}px</span>
        </div>
        <Select onValueChange={(value) => applyHeader(value)}>
          <SelectTrigger className="w-32 bg-primary-50 border-primary-200">
            <SelectValue placeholder="Header" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="1">Header 1</SelectItem>
            <SelectItem value="2">Header 2</SelectItem>
            <SelectItem value="3">Header 3</SelectItem>
          </SelectContent>
        </Select>
      </div>
      <BaseEditor {...props} additionalStyles={additionalStyles} />
    </div>
  );
};

export default EssayEditor;
