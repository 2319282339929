export function getInstructions(name, question) {
  const formattedQuestion = question ? formatQuestion(question) : "";

  return `Assist me with the assignment question: ${formattedQuestion}. Instead of giving me the answer, have a conversation 
      with me, acting as a human tutor. Balance providing relevant information/lecture with asking questions to get me thinking.
      My name is ${name}. Use vocabulary and knowledge at high school level. I have to solve this by myself, without you giving me the answer. 
      If I get off track from answering the question, redirect me back toward it. Finally, structure your response in HTML format with friendly formatting 
      to prevent walls of text and making the content digestable for large bodies of text. Replace formatting asterisks with html list formatting.`;
}

export function getVoiceInstructions(name, question) {
  const formattedQuestion = question ? formatQuestion(question) : "";
  return `Assist me with the assignment question: ${formattedQuestion}. Instead of giving me the answer, have a conversation 
  with me, acting as a human tutor. Balance providing relevant information/lecture with asking questions to get me thinking.
  My name is ${name}. Use vocabulary and knowledge at high school level. I have to solve this by myself, without you giving me the answer. 
  If I get off track from answering the question, redirect me back toward it. Start the conversation by focusing my attention on the question`;
}

//Based on the question type, format the question so GPT understands it better

function formatQuestion(question) {
  if (question.type === "short-answer") {
    return `In a short answer format, answer: ${question.text}`;
  }
  if (question.type === "multiple-choice") {
    var choicesString = "";
    var charArray = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    for (var i = 0; i < question.choices.length; i++) {
      choicesString += charArray[i] + ": " + question.choices[i] + "; ";
    }
    return `Answer the following multiple choice question: ${question.text}. The choices are: ${choicesString}`;
  }
  if (question.type === "select-all") {
    var choicesString = "";
    var charArray = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    for (var i = 0; i < question.choices.length; i++) {
      choicesString += charArray[i] + ": " + question.choices[i] + "; ";
    }
    return `Answer the following select-all-that-apply question: ${question.text}. The choices are: ${choicesString}`;
  }
  if (question.type === "essay") {
    return `In an essay format, answer: ${question.text}`;
  }
  if (question.type === "coding") {
    return `This is a coding question in ${question.codingLanguage}. The task is to ${question.text}. The starter code 
    is ${question.rawStarterCode}`;
  } else {
    //currently no specific response for file uploads. Don't see the point
    return question.text;
  }
}
