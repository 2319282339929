import React, { useRef, useState } from "react";
import FileUploader from "./FileUploader";
import { Button } from "./ui/button";
import { Alert, AlertDescription } from "./ui/alert.jsx";
import { Loader2, FileCheck, Sparkles } from "lucide-react";
import { FileUtils } from "./FileConverter";
import api from "./api";
import { useAssignmentTool } from "./useAssignmentTool";
import { AnimatePresence, motion } from "framer-motion";
import QuestionsEditor from "./QuestionEditor";

const AssignmentGenerator = () => {
  const { handleGeneration, setTitle } = useAssignmentTool();
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const fileUploaderRef = useRef(null);
  const [showEditor, setShowEditor] = useState(false);

  const handleFileChange = (selectedFile) => {
    setFile(selectedFile);
    setError("");
  };

  const processFile = async (file) => {
    switch (file.type) {
      case "application/pdf":
        return await FileUtils.extractTextFromPDF(file);
      case "image/png":
      case "image/jpeg":
      case "image/jpg":
        // const imageResult = await FileUtils.extractTextFromImage(file);
        // return imageResult.text;
        const base64 = await FileUtils.convertToBase64(file);
        return base64;
      default:
        throw new Error(`Unsupported file type: ${file.type}`);
    }
  };

  const handleGenerateQuestions = async () => {
    if (!file) {
      setError("Please upload a file first");
      return;
    }

    try {
      setIsLoading(true);
      const extractedContent = await processFile(file);

      if (file.type === "application/pdf") {
        if (extractedContent.pages.length > 5) {
          fileUploaderRef.current?.clearFileInput();
          setFile(null);
          setError(
            "PDF's cannot be longer than 5 pages. Please submit a shorter PDF"
          );
          return;
        }

        const pagePromises = extractedContent.pages.map(async (page) => {
          const response = await api.post("/generate-assignment", {
            documentText: page.text,
            fileName: `${file.name} - Page ${page.pageNum}`,
            fileType: file.type,
            pageNum: page.pageNum,
          });
          if (page.pageNum === 1) {
            setTitle(response.data?.title || file.name);
          }
          return response.data.questions;
        });

        const pageResults = await Promise.all(pagePromises);
        const allQuestions = pageResults.flat();

        handleGeneration(allQuestions, file);
      } else {
        // const response = await api.post("/generate-assignment", {
        //   documentText: extractedContent,
        //   fileName: file.name,
        //   fileType: file.type,
        // });
        // setTitle(file.name);

        // handleGeneration(response.data.questions, file);

        const response = await api.post("/generate-assignment/image", {
          base64Text: extractedContent,
          fileName: file.name,
          fileType: file.type,
        });
        setTitle(file.name);
        handleGeneration(response.data.questions, file);
      }
      setShowEditor(true);
      fileUploaderRef.current?.clearFileInput();
      setFile(null);
    } catch (err) {
      setError(`Failed to process file: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AnimatePresence mode="wait">
      {showEditor ? (
        <motion.div
          key="editor"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
        >
          <QuestionsEditor />
        </motion.div>
      ) : (
        <motion.div
          key="uploader"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          className="space-y-6"
        >
          <FileUploader
            ref={fileUploaderRef}
            onFileChange={handleFileChange}
            error={error}
          />

          {file && !error && (
            <Alert variant="default" className="bg-primary/5 border-primary/10">
              <AlertDescription className="flex items-center">
                <FileCheck className="w-4 h-4 mr-2 text-primary" />
                Ready to process:{" "}
                <span className="font-medium ml-1">{file.name}</span>
              </AlertDescription>
            </Alert>
          )}

          <div className="flex justify-between">
            <Button
              disabled={isLoading}
              onClick={() => setShowEditor(true)}
              variant="outline"
            >
              Skip Upload
            </Button>
            <Button
              onClick={handleGenerateQuestions}
              disabled={!file || isLoading}
              className="min-w-[200px]"
              size="lg"
            >
              {isLoading ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Processing...
                </>
              ) : (
                <>
                  <Sparkles className="mr-2 h-4 w-4" />
                  Create Assignment
                </>
              )}
            </Button>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
export default AssignmentGenerator;
