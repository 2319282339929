import { useEffect, useRef, useState } from "react";
import EssayEditor from "../TextEditors/EssayEditor";

const EssayQuestion = ({
  question,
  answers,
  setAnswers,
  assignmentId,
  studentId,
}) => {
  const [currentContent, setCurrentContent] = useState("");
  const [initialContentInitialized, setInitialContentRendered] =
    useState(false);

  useEffect(() => {
    setCurrentContent(answers[question.id] || "");
  }, [question.id, answers]);

  useEffect(() => {
    setInitialContentRendered(false);
  }, [question.id]);

  const handleChange = (answer) => {
    setCurrentContent(answer);
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [question.id]: answer,
    }));
  };

  return (
    <div>
      <EssayEditor
        onChange={handleChange}
        content={currentContent}
        initialContentRendered={initialContentInitialized}
        setInitialContentRendered={setInitialContentRendered}
      />
    </div>
  );
};

export default EssayQuestion;
