import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Mic, Square } from "lucide-react";
import { Alert, AlertDescription } from "../ui/alert.jsx";
import api from "../api";
import { getVoiceInstructions } from "./InstructionFormatter.js";
import { useUser } from "../contexts/userContext";

const VoiceChat = forwardRef(({ question }, ref) => {
  const [connectionState, setConnectionState] = useState("idle");
  const [error, setError] = useState(null);
  const [isReceivingAudio, setIsReceivingAudio] = useState(false);
  const [isMicrophoneActive, setIsMicrophoneActive] = useState(false);
  const [instructions, setInstructions] = useState("");
  const { user } = useUser();
  const peerConnection = useRef(null);
  const dataChannel = useRef(null);
  const audioElement = useRef(new Audio());
  const mediaStream = useRef(null);
  const audioContext = useRef(null);
  const audioAnalyser = useRef(null);
  const animationFrame = useRef(null);

  const cleanup = useCallback(() => {
    // Cancel any ongoing animation frame
    if (animationFrame.current) {
      cancelAnimationFrame(animationFrame.current);
      animationFrame.current = null;
    }

    // Close audio context
    if (audioContext.current?.state !== "closed") {
      audioContext.current?.close();
      audioContext.current = null;
    }

    // Stop all media tracks
    if (mediaStream.current) {
      mediaStream.current.getTracks().forEach((track) => {
        track.stop();
      });
      mediaStream.current = null;
    }

    // Close data channel
    if (dataChannel.current) {
      dataChannel.current.close();
      dataChannel.current = null;
    }

    // Close peer connection
    if (peerConnection.current) {
      peerConnection.current.close();
      peerConnection.current = null;
    }

    // Clear audio element
    if (audioElement.current) {
      audioElement.current.srcObject = null;
    }

    // Reset states
    setConnectionState("idle");
    setIsReceivingAudio(false);
    setIsMicrophoneActive(false);
  }, []);

  useImperativeHandle(ref, () => ({
    cleanup,
  }));

  useEffect(() => {
    cleanup();
    return cleanup;
  }, [question, cleanup]);

  useEffect(() => {
    setInstructions(getVoiceInstructions(user?.firstName, question));
  }, [question, user]);

  const setupAudioAnalyser = (stream) => {
    // Create new audio context and analyser
    audioContext.current = new AudioContext();
    audioAnalyser.current = audioContext.current.createAnalyser();
    audioAnalyser.current.fftSize = 2048;

    // Create source from stream
    const source = audioContext.current.createMediaStreamSource(stream);
    source.connect(audioAnalyser.current);

    const dataArray = new Uint8Array(audioAnalyser.current.frequencyBinCount);

    const checkAudioActivity = () => {
      if (!audioAnalyser.current) return;

      audioAnalyser.current.getByteFrequencyData(dataArray);
      const average = dataArray.reduce((a, b) => a + b, 0) / dataArray.length;

      // Using a higher threshold for more accurate detection
      setIsReceivingAudio(average > 20);

      animationFrame.current = requestAnimationFrame(checkAudioActivity);
    };

    checkAudioActivity();
  };

  const setupPeerConnection = async (EPHEMERAL_KEY) => {
    const pc = new RTCPeerConnection({
      iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
    });

    pc.onconnectionstatechange = () => {
      if (pc.connectionState === "failed" || pc.connectionState === "closed") {
        setError("WebRTC connection failed");
        cleanup();
      }
    };

    pc.oniceconnectionstatechange = () => {
      if (
        pc.iceConnectionState === "failed" ||
        pc.iceConnectionState === "disconnected"
      ) {
        setError("Network connection lost");
        cleanup();
      }
    };

    pc.ontrack = (event) => {
      audioElement.current.srcObject = event.streams[0];
      setupAudioAnalyser(event.streams[0]);

      audioElement.current.onloadedmetadata = () => {
        audioElement.current
          .play()
          .catch((err) => console.error("Audio playback failed:", err));
      };
    };

    return pc;
  };

  const setupDataChannel = (pc) => {
    pc.ondatachannel = (event) => {
      const dc = event.channel;

      dc.onopen = () => {
        initializeVoiceStream(dc);
      };

      dc.onclose = () => {
        if (connectionState === "active") {
          setError("Connection closed unexpectedly");
          cleanup();
        }
      };

      dc.onerror = (error) => {
        console.error("Data channel error:", error);
        setError("Data channel error occurred");
        cleanup();
      };

      dc.onmessage = (event) => {
        const data = JSON.parse(event.data);
        // Handle any messages that might indicate when the assistant starts/stops speaking
        if (data.type === "speech.start") {
          setIsReceivingAudio(true);
        } else if (data.type === "speech.end") {
          setIsReceivingAudio(false);
        }
      };

      dataChannel.current = dc;
    };
  };

  const toggleMicrophone = async () => {
    if (connectionState === "active") {
      cleanup();
    } else {
      await initializeWebRTC();
    }
  };

  const initializeWebRTC = async () => {
    cleanup();
    setConnectionState("connecting");
    setError(null);

    try {
      const { data: sessionData } = await api.get("/voice/session");

      if (!sessionData?.client_secret?.value) {
        throw new Error("Invalid session response");
      }

      const EPHEMERAL_KEY = sessionData.client_secret.value;

      const pc = await setupPeerConnection(EPHEMERAL_KEY);
      peerConnection.current = pc;

      try {
        mediaStream.current = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        setIsMicrophoneActive(true);

        mediaStream.current.getTracks().forEach((track) => {
          pc.addTrack(track, mediaStream.current);
          // Listen for the track's ended event
          track.onended = () => {
            setIsMicrophoneActive(false);
            cleanup();
          };
        });
      } catch (err) {
        console.error("Microphone access error:", err);
        throw new Error("Microphone access denied");
      }

      setupDataChannel(pc);

      const offer = await pc.createOffer();
      await pc.setLocalDescription(offer);

      const { data: sdpAnswer } = await api.post("/voice/connect", {
        sdp: offer.sdp,
        ephemeralKey: EPHEMERAL_KEY,
        instructions,
      });

      await pc.setRemoteDescription({
        type: "answer",
        sdp: sdpAnswer,
      });

      setConnectionState("active");
    } catch (err) {
      console.error("Error in WebRTC initialization:", err);
      setError(err.response?.data?.error || err.message);
      cleanup();
    }
  };

  const initializeVoiceStream = (dc) => {
    if (!dc || dc.readyState !== "open") {
      console.error("Data channel not ready. State:", dc?.readyState);
      setError("Data channel not ready");
      return;
    }

    const message = {
      type: "response.create",
      response: {
        modalities: ["audio"],
        instructions,
      },
    };

    try {
      dc.send(JSON.stringify(message));
    } catch (err) {
      console.error("Error sending initial message:", err);
      setError("Failed to initialize voice stream");
      cleanup();
    }
  };

  const getMicrophoneStyles = () => {
    if (connectionState === "connecting") {
      return "bg-gray-100";
    } else if (connectionState === "active") {
      return isReceivingAudio
        ? "bg-blue-100 ring-4 ring-blue-200"
        : "bg-green-100 ring-4 ring-green-200";
    }
    return "bg-gray-100 hover:bg-gray-200 transition-colors";
  };

  const getMicrophoneIconStyles = () => {
    if (connectionState === "connecting") {
      return "text-gray-400 animate-pulse";
    } else if (connectionState === "active") {
      return isReceivingAudio ? "text-blue-600" : "text-green-600";
    }
    return "text-gray-600";
  };

  const getStatusText = () => {
    if (connectionState === "connecting") {
      return "Connecting...";
    } else if (connectionState === "active") {
      return isReceivingAudio
        ? "Sparky is speaking..."
        : isMicrophoneActive
        ? "Listening to you..."
        : "Click to start";
    }
    return "Click to start";
  };

  return (
    <div className="flex flex-col items-center justify-center gap-4 h-full">
      {error && (
        <Alert variant="destructive" className="w-96">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      <div className="flex flex-col items-center gap-4">
        <div className="relative">
          {connectionState === "active" && (
            <div
              className="absolute inset-0 -m-2 rounded-full bg-opacity-25 animate-ping"
              style={{
                backgroundColor: isReceivingAudio ? "#93C5FD" : "#86EFAC",
                animationDuration: "2s",
              }}
            />
          )}
          <div className="grid col gap-4">
            <button
              onClick={toggleMicrophone}
              className={`p-4 rounded-full transition-all duration-300 ${getMicrophoneStyles()}`}
              disabled={connectionState === "connecting"}
            >
              <Mic
                className={`h-8 w-8 transition-colors ${getMicrophoneIconStyles()}`}
              />
            </button>
          </div>
        </div>
        <span className="text-sm text-gray-600 animate-fade-in">
          {getStatusText()}
        </span>
        {connectionState === "active" && (
          <button className="mt-4" onClick={() => cleanup()}>
            <span className="flex text-red-600">
              Stop <Square className="ml-2 h-6 h-6" />{" "}
            </span>
          </button>
        )}
      </div>
    </div>
  );
});

export default VoiceChat;
