const DashboardHeader = () => {
  return (
    <div className="text-center space-y-2 mb-12">
      <h1 className="text-4xl font-bold text-primary">Assignment Dashboard</h1>
      <p className="text-muted-foreground">
        Upload and manage your assignments
      </p>
    </div>
  );
};

export default DashboardHeader;
