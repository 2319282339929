import { formatMessage, cn } from "../utils";
import AudioPlayer from "../AudioPlayer";

const ChatMessage = ({ type, content, timestamp }) => {
  const messageDate = new Date(timestamp);

  return (
    <div
      className={cn(
        "p-3 rounded-lg shadow-sm max-w-[80%]",
        type === "user"
          ? "bg-primary/15 text-primary-foreground ml-auto"
          : "bg-secondary/15 text-secondary-foreground mr-auto"
      )}
    >
      <div className="flex justify-between items-center mb-1">
        <span
          className={cn(
            "font-medium text-sm",
            type === "user" ? "text-primary" : "text-secondary"
          )}
        >
          <div className="flex items-center">
            {type === "user" ? "You" : "Sparky"}
            {type !== "user" && <AudioPlayer content={content} />}
          </div>
        </span>
        <span className="text-xs text-muted-foreground">
          {messageDate.toLocaleString(undefined, {
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          })}
        </span>
      </div>
      <div className="text-sm text-foreground">
        <span>{formatMessage(content)}</span>
      </div>
    </div>
  );
};

export default ChatMessage;
