const Switch = ({ checked, onChange }) => {
  return (
    <button
      type="button"
      role="switch"
      aria-checked={checked}
      onClick={() => onChange(!checked)}
      className={`
          relative inline-flex h-6 w-11 items-center rounded-full
          ${checked ? "bg-blue-600" : "bg-gray-200"}
          transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2
        `}
    >
      <span className="sr-only">Toggle voice chat</span>
      <span
        className={`
            inline-block h-4 w-4 transform rounded-full bg-white transition-transform duration-200 ease-in-out
            ${checked ? "translate-x-6" : "translate-x-1"}
          `}
      />
    </button>
  );
};
export default Switch;
