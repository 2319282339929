import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  PlusCircle,
  ChevronDown,
  ChevronRight,
  FileText,
  Check,
  Loader2,
} from "lucide-react";
import { Button } from "./ui/button";
import { Card, CardContent } from "./ui/card";
import Question from "./Question";
import { useAssignmentTool } from "./useAssignmentTool";
import { useUser } from "./contexts/userContext";
import { useNavigate } from "react-router-dom";
import api from "./api";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { uploadFile } from "./utils";

const QuestionsEditor = () => {
  const { questions, title, setTitle, addQuestion, deleteQuestion, file, clearFields } =
    useAssignmentTool();
  const [expandedQuestions, setExpandedQuestions] = useState(new Set([0]));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { user, updateAssignments } = useUser();
  const userId = user.uid;
  const navigate = useNavigate();
  const [invalidQuestions, setInvalidQuestions] = useState(true);
  const [deletingIndex, setDeletingIndex] = useState(null);

  useEffect(() => {
    if (questions.length < 1) {
      setInvalidQuestions(true);
    } else {
      const hasInvalidQuestion = questions.some(
        (question) =>
          question.type?.trim() === "" ||
          question.text?.trim() === "" ||
          ((question.type === "multiple-choice" ||
            question.type === "select-all") &&
            question.choices?.length < 1)
      );

      setInvalidQuestions(hasInvalidQuestion);
    }
  }, [questions]);

  const toggleQuestion = (index) => {
    setExpandedQuestions((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(index)) {
        newSet.delete(index);
      } else {
        newSet.add(index);
      }
      return newSet;
    });
  };

  const handleAddQuestion = () => {
    const newIndex = questions.length;
    addQuestion();
    setExpandedQuestions((prev) => new Set([...prev, newIndex]));
  };

  const handleDeleteQuestion = async (index) => {
    // Set the deleting index to trigger exit animation
    setDeletingIndex(index);

    // Remove the index from expanded questions
    setExpandedQuestions((prev) => {
      const newSet = new Set(prev);
      newSet.delete(index);
      // Adjust expanded indices above the deleted question
      const adjustedSet = new Set();
      newSet.forEach((expandedIndex) => {
        if (expandedIndex < index) {
          adjustedSet.add(expandedIndex);
        } else if (expandedIndex > index) {
          adjustedSet.add(expandedIndex - 1);
        }
      });
      return adjustedSet;
    });

    // Delete the question after a small delay to allow for animation
    setTimeout(() => {
      deleteQuestion(index);
      setDeletingIndex(null);
    }, 200); // Matches animation duration
  };

  const handleConfirm = async () => {
    setLoading(true);
    try {
      let fileUrl = null;

      if (file) {
        try {
          fileUrl = await uploadFile(file);
        } catch (uploadError) {
          setError(`File upload failed: ${uploadError.message}`);
          setLoading(false);
          return;
        }
      }

      const assignmentResponse = await api.post("/personal-assignments", {
        questions,
        title,
        userId,
        fileUrl,
      });
      const assignmentId = assignmentResponse.data.assignment.id;

      await api.post(`/users/${userId}/personal-assignment`, {
        assignmentId,
      });
      updateAssignments(assignmentResponse.data.assignment);

      navigate(`/assignment/${assignmentId}`, {
        state: { assignment: assignmentResponse.data.assignment },
      });
      clearFields();
    } catch (error) {
      setError(
        "An error occurred processing your assignment. Please try again ."
      );
      setTimeout(() => {
        setError(null);
      }, [3000]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="space-y-6"
    >
      {/* Title Input */}
      <div className="space-y-4">
        <Label htmlFor="title" className="text-lg font-medium">
          Assignment Title
        </Label>
        <Input
          id="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="text-lg font-medium border-2 border-primary/10 focus:border-primary/30 transition-colors"
          placeholder="Enter assignment title..."
        />
      </div>

      {/* Questions List */}
      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <h2 className="text-lg font-medium flex items-center">
            <FileText className="mr-2 h-5 w-5 text-primary" />
            Questions
          </h2>
          <span className="text-sm text-muted-foreground">
            Total Questions: {questions.length}
          </span>
        </div>

        <AnimatePresence mode="popLayout">
          {questions.map((question, index) => (
            <motion.div
              key={`question-${index}`}
              layout
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.2 }}
            >
              <Card className="border-2 border-primary/10 hover:border-primary/20 transition-all duration-200">
                <div
                  className="p-4 flex items-center justify-between cursor-pointer hover:bg-primary/5 transition-colors"
                  onClick={() => toggleQuestion(index)}
                >
                  <div className="flex items-center space-x-3">
                    {expandedQuestions.has(index) ? (
                      <ChevronDown className="h-5 w-5 text-primary" />
                    ) : (
                      <ChevronRight className="h-5 w-5 text-primary" />
                    )}
                    <div className="space-y-1">
                      <h3 className="font-medium">Question {index + 1}</h3>
                      <p className="text-sm text-muted-foreground">
                        {question.type || "No type selected"} •{" "}
                        {question.text
                          ? question.text.slice(0, 50) +
                            (question.text.length > 50 ? "..." : "")
                          : "No text entered"}
                      </p>
                    </div>
                  </div>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteQuestion(index);
                    }}
                    disabled={deletingIndex === index}
                    className="text-destructive hover:text-destructive/90"
                  >
                    Delete
                  </Button>
                </div>

                <AnimatePresence>
                  {expandedQuestions.has(index) && (
                    <motion.div
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: "auto", opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                    >
                      <CardContent className="border-t border-primary/10 bg-primary/5">
                        <Question index={index} />
                      </CardContent>
                    </motion.div>
                  )}
                </AnimatePresence>
              </Card>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>

      {/* Actions */}
      <div className="flex justify-between items-center pt-4">
        <Button
          onClick={handleAddQuestion}
          variant="outline"
          className="border-2 border-primary/20 hover:border-primary/40"
          disabled={loading}
        >
          <PlusCircle className="mr-2 h-4 w-4" /> Add Question
        </Button>
        <Button
          onClick={handleConfirm}
          disabled={loading || invalidQuestions || title.trim() === ""}
          size="lg"
          className="min-w-[200px]"
        >
          {loading ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Processing...
            </>
          ) : (
            <>
              <Check className="mr-2 h-4 w-4" />
              Finalize Assignment
            </>
          )}
        </Button>
      </div>
      {error && <p></p>}
    </motion.div>
  );
};

export default QuestionsEditor;
