import { Navigate, useLocation, useParams } from "react-router-dom";
import { useUser } from "./contexts/userContext";
import LoadingScreen from "./LoadingPage";

const ProtectedRoute = ({ children, accessRule }) => {
  const { user, loading, assignments } = useUser();

  const params = useParams();

  if (loading) {
    return <LoadingScreen />;
  }

  const hasAccess = accessRule
    ? accessRule({ ...user, assignments }, params)
    : true;

  if (!hasAccess) {
    return <Navigate to="/unauthorized" replace />;
  }

  return children;
};

export default ProtectedRoute;
