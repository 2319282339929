import React, { useEffect, useRef } from "react";
import { Bold, Italic, Underline } from "lucide-react";
import { Button } from "../ui/button.jsx";

const BaseEditor = ({
  onChange,
  content,
  initialContentRendered,
  setInitialContentRendered,
  additionalStyles = [],
}) => {
  const editorRef = useRef(null);

  useEffect(() => {
    if (editorRef.current && content !== undefined && !initialContentRendered) {
      editorRef.current.innerHTML = content;
      setInitialContentRendered(true);
    }
  }, [content, initialContentRendered]);

  const handleInput = () => {
    if (editorRef.current) {
      const newContent = editorRef.current.innerHTML;
      onChange(newContent);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      document.execCommand("insertHTML", false, "&nbsp;&nbsp;&nbsp;&nbsp;");
    } else if (event.key === "Enter") {
      event.preventDefault();
      document.execCommand("insertLineBreak");
    }
  };

  const applyStyle = (style) => {
    document.execCommand(style, false, null);
    handleInput();
    editorRef.current.focus();
  };

  return (
    <div className="max-w-2xl mx-auto">
      <div className="bg-white p-4 rounded-lg shadow-sm">
        <div className="mb-2 flex items-center space-x-1">
          {[
            { icon: Bold, action: () => applyStyle("bold") },
            { icon: Italic, action: () => applyStyle("italic") },
            { icon: Underline, action: () => applyStyle("underline") },
            ...additionalStyles,
          ].map((item, index) => (
            <Button
              key={index}
              variant="outline"
              size="icon"
              onClick={item.action}
              className="bg-primary-50 border-primary-200 hover:bg-primary-100"
            >
              <item.icon size={16} className="text-primary-600" />
            </Button>
          ))}
        </div>
        <div
          ref={editorRef}
          className="border border-primary focus:ring-primary p-4 min-h-[150px] prose prose-primary rounded-md focus:outline-none"
          contentEditable={true}
          onInput={handleInput}
          onKeyDown={handleKeyDown}
          style={{
            fontFamily: "Arial",
            fontSize: "16px",
            lineHeight: "1.5",
            whiteSpace: "pre-wrap",
          }}
        />
      </div>
    </div>
  );
};

export default BaseEditor;
