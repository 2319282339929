import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ChatCard from "./ChatCard";
import InterfaceHeader from "./InterfaceHeader";
import { InterfaceService } from "./InterfaceService";
import QuestionCard from "./QuestionCard";
import useAssignmentInterface from "./useAssignmentInterface";
import { useUser } from "../contexts/userContext";

const AssignmentInterface = ({ assignment, initialAnswers, userId }) => {
  const assignmentId = assignment.id;
  const chatLogId = `${userId}_${assignmentId}`;
  const [submitting, setSubmitting] = useState(false);
  const [saving, setSaving] = useState(false);
  const [sendingAnswers, setSendingAnswers] = useState(false);
  const [lastSavedAnswers, setLastSavedAnswers] = useState(initialAnswers);
  const { updateSubmissions } = useUser();

  const navigate = useNavigate();

  const {
    questions,
    answers,
    setAnswers,
    currentQuestion,
    currentIndex,
    setCurrentIndex,
    error,
    handleError,
  } = useAssignmentInterface(assignment, initialAnswers);

  useEffect(() => {
    const saveInterval = setInterval(() => {
      setLastSavedAnswers((prevLastSavedAnswers) => {
        if (answers !== prevLastSavedAnswers) {
          InterfaceService.saveProgress(userId, assignmentId, answers);
          updateSubmissions(assignmentId, answers);
          return answers;
        }
        return prevLastSavedAnswers;
      });
    }, 60 * 1000);

    return () => clearInterval(saveInterval);
  }, [answers, updateSubmissions]);

  const handleNextQuestion = () => {
    setCurrentIndex((prevIndex) => prevIndex + 1);
  };

  const handlePreviousQuestion = () => {
    setCurrentIndex((prevIndex) => prevIndex - 1);
  };

  const onSaveAndExit = async () => {
    setSaving(true);
    setSendingAnswers(true);
    try {
      await InterfaceService.saveProgress(userId, assignmentId, answers);
      updateSubmissions(assignmentId, answers);
      navigate("/homepage");
    } catch (error) {
      handleError("Sorry, something went wrong. Please try again later. ");
    } finally {
      setSendingAnswers(false);
      setSaving(false);
    }
  };

  if (questions.length === 0) {
    return <div>No questions found for this assignment</div>;
  }

  // if (loading || !assignment || !initialAnswers) {
  //   return <AssignmentInterfaceSkeleton />;
  // }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <main className="flex-1 flex flex-col">
        <div className="max-w-7xl mx-auto w-full flex-1 flex flex-col py-6 sm:px-6 lg:px-8">
          <InterfaceHeader
            saving={saving}
            sendingAnswers={sendingAnswers}
            onSaveAndExit={onSaveAndExit}
            currentIndex={currentIndex + 1}
            numberOfQuestions={questions.length}
            error={error}
          />

          <div className="flex gap-6 h-[800px] mt-6">
            <QuestionCard
              currentQuestion={currentQuestion}
              currentIndex={currentIndex}
              questionsLength={questions.length}
              answers={answers}
              setAnswers={setAnswers}
              assignmentId={assignmentId}
              userId={userId}
              handleNextQuestion={handleNextQuestion}
              handlePreviousQuestion={handlePreviousQuestion}
            />

            <ChatCard
              currentQuestion={currentQuestion}
              chatLogId={chatLogId}
              saving={saving}
              submitting={submitting}
              fileUrl={assignment.fileUrl}
            />
          </div>
        </div>
      </main>
    </div>
  );
};

export default AssignmentInterface;
