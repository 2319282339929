import { Plus, ArrowLeft } from "lucide-react";
import { Button } from "./ui/button";
import { Card, CardHeader, CardTitle, CardContent } from "./ui/card.jsx";
import { AnimatePresence, motion } from "framer-motion";
import AssignmentGenerator from "./AssignmentGenerator";

const EmptyState = ({ onStart }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex flex-col items-center justify-center py-12 space-y-4"
    >
      <div
        className="bg-primary/10 p-6 rounded-full cursor-pointer transition-colors hover:bg-primary/55"
        onClick={onStart}
      >
        <Plus className="h-12 w-12 text-primary" />
      </div>
      <h3 className="text-xl font-semibold text-center">
        Upload Your Homework
      </h3>
      <p className="text-muted-foreground text-center max-w-sm">
        Upload your assignment to work with a tutor in our interface
      </p>
      <Button size="lg" onClick={onStart} className="mt-4">
        Get Started
      </Button>
    </motion.div>
  );
};

const GeneratorView = ({ onComplete }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="space-y-4"
    >
      <AssignmentGenerator onComplete={onComplete} />
    </motion.div>
  );
};

export const GenerateAssignmentCard = ({ isGenerating, setIsGenerating }) => {
  return (
    <motion.div layout className="h-full">
      {isGenerating && (
        <Button
          variant="ghost"
          className="mb-4"
          onClick={() => setIsGenerating(false)}
        >
          <ArrowLeft className="mr-2 h-4 w-4" />
          Back to Dashboard
        </Button>
      )}
      <Card className="shadow-lg border-2 border-primary/10 hover:border-primary/20 transition-all duration-300 h-full">
        <CardHeader
          className={`bg-primary/5 border-b border-primary/10 transition-all duration-300 ${
            isGenerating ? "bg-primary/10" : ""
          }`}
        >
          <CardTitle className="flex items-center text-2xl">
            <Plus className="mr-2 h-6 w-6" />
            {isGenerating ? "Create Assignment" : "New Assignment"}
          </CardTitle>
        </CardHeader>
        <CardContent className="p-6">
          <AnimatePresence mode="wait">
            {isGenerating ? (
              <GeneratorView onComplete={() => setIsGenerating(false)} />
            ) : (
              <EmptyState onStart={() => setIsGenerating(true)} />
            )}
          </AnimatePresence>
        </CardContent>
      </Card>
    </motion.div>
  );
};

export default GenerateAssignmentCard;
