import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useUser } from "./contexts/userContext";
import AssignmentInterface from "./AssignmentInterface/AssignmentInterface";
import { useLocation } from "react-router-dom";

const AssignmentInterfacePage = () => {
  const { assignmentId } = useParams();
  const { user, assignments, submissions, loading: userLoading } = useUser();

  const location = useLocation();
  const { assignment, initialAnswers } = useMemo(() => {
    const navigationAssignment = location.state?.assignment;
    const assignment =
      navigationAssignment ||
      assignments.find((assignment) => assignment.id === assignmentId);

    const submission = submissions.find(
      (submission) => submission.assignmentId === assignment?.id
    );
    const initialAnswers = submission ? submission.answers : {};
    return { assignment, initialAnswers };
  }, [assignmentId, assignments, submissions, location.state]);

  if (!assignment) {
    return <div>Assignment not found</div>;
  }

  return (
    <AssignmentInterface
      assignment={assignment}
      initialAnswers={initialAnswers}
      userId={user?.uid}
      loading={userLoading}
    />
  );
};

export default AssignmentInterfacePage;
