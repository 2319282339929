import { Button } from "../ui/button.jsx";
import React from "react";
import { useNavigate } from "react-router-dom";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  render() {
    if (this.state.hasError) {
      return <ErrorFallback error={this.state.error} />;
    }
    return this.props.children;
  }
}

const ErrorFallback = ({ error }) => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="max-w-md p-8 bg-white rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-red-600 mb-4">
          Oops! Something went wrong
        </h2>

        <p className="text-gray-600 mb-6">
          We're sorry for the inconvenience. You can try:
        </p>

        <div className="space-y-4">
          <Button
            onClick={() => window.location.reload()}
            className="w-full p-2"
          >
            Refresh the page
          </Button>

          <Button onClick={() => navigate("/homepage")} className="w-full p-2">
            Return to homepage
          </Button>
        </div>

        <p className="text-gray-600 mb-6 mt-6">
          Our team is working to debug this issue. If the problem persists or it
          is urgent, please reach out to{" "}
          <a
            href="mailto:jake@AilaEducation.com"
            className="text-primary hover:underline"
          >
            jake@AilaEducation.com
          </a>
        </p>

        {process.env.NODE_ENV === "development" && (
          <div className="mt-6 p-4 bg-gray-100 rounded">
            <p className="text-sm font-mono text-gray-700">
              {error.toString()}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ErrorBoundary;
