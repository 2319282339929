import axios from "axios";
import { auth } from "./firebase";

let baseURL;

if (process.env.NODE_ENV === "production") {
  baseURL = process.env.REACT_APP_SERVER_BASE_URL;
} else {
  baseURL = process.env.REACT_APP_TESTING_BASE_URL;
}

// Create the api instance first
const api = axios.create({
  baseURL: baseURL,
});


api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("authToken");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const user = auth.currentUser;
        if (!user) {
          throw new Error("No user found");
        }

        const newToken = await user.getIdToken(true);
        localStorage.setItem("authToken", newToken);

        originalRequest.headers["Authorization"] = `Bearer ${newToken}`;

        return api(originalRequest);
      } catch (refreshError) {
        const { logout } = await import("./hooks/useAuth");
        await logout();
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default api;
