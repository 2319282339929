// PageTracker.js
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import posthog from "posthog-js";

const PageTracker = () => {
  const location = useLocation();
  const [pageLoadTime] = useState(Date.now());

  useEffect(() => {
    posthog.capture("$pageview");

    const handleBeforeUnload = () => {
      const timeSpent = Date.now() - pageLoadTime;

      posthog.capture("$pageleave");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [location, pageLoadTime]);

  return null; // This component doesn't render anything
};

export default PageTracker;
