import { CircleUserRound } from "lucide-react";
import { useRef, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useUser } from "./contexts/userContext";
import LogoutButton from "./auth/LogoutButton";
import { Button } from "./ui/button.jsx";
import { PolicyLink } from "./PrivacyPolicy";

const BaseLayout = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useUser();

  const handleProfileClick = () => {
    navigate(`/profile/${user.uid}`);
  };

  const isActiveLink = (path) => {
    if (path === "/homepage") {
      return location.pathname === "/homepage";
    }
    if (path === "courses") {
      return location.pathname.startsWith("/course/");
    }
    if (path === "plans") {
      return location.pathname.startsWith("/plans");
    }
    return location.pathname === path;
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <header className="bg-white shadow-sm">
        <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <div className="flex-shrink-0 flex items-center">
                <img
                  src="/HeaderLogo.png"
                  alt="Header Logo"
                  className="h-8 w-auto"
                />
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:space-x-6">
                <Link
                  to="/homepage"
                  className={`${
                    isActiveLink("/homepage")
                      ? "border-primary text-gray-900"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                  } inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
                >
                  Home
                </Link>
              </div>
              {/* <div className="flex mx-4">
                {!user.paid && (
                  <a
                    href="/plans"
                    className={`${
                      isActiveLink("plans")
                        ? "border-primary text-gray-900"
                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    } inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
                  >
                    Upgrade
                  </a>
                )}
              </div> */}
              {/* <div className="flex">
                {user.role === "teacher" && (
                  <a
                    href="/about"
                    className={`${
                      isActiveLink("/about")
                        ? "border-primary text-gray-900"
                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    } inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
                  >
                    About
                  </a>
                )}
              </div> */}
            </div>
            <div className="flex items-center space-x-4">
              <span className="text-sm font-medium text-gray-700 flex items-center">
                Welcome, {user.firstName}{" "}
                <Button
                  onClick={handleProfileClick}
                  className="ml-2 bg-transparent hover:bg-primary/15 transition-colors text-primary"
                >
                  <CircleUserRound className="h-6 w-6" />
                </Button>
              </span>
              <LogoutButton />
            </div>
          </div>
        </nav>
      </header>

      <main className="flex-grow">
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">{children}</div>
      </main>

      <footer className="bg-white border-t border-gray-200">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="py-8">
            <div className="flex flex-col md:flex-row md:items-center md:justify-between space-y-4 md:space-y-0">
              <p className="text-gray-500 text-sm">
                Copyright © 2025 Aila Education, LLC
              </p>
              <div className="flex space-x-6">
                <a
                  className="text-gray-600 text-sm hover:text-gray-900 hover:underline cursor-pointer"
                  onClick={() => navigate("/privacy-policy")}
                >
                  Privacy Policy
                </a>
                <a
                  href="mailto:jake@AilaEducation.com"
                  className="text-gray-600 text-sm hover:text-gray-900 hover:underline"
                >
                  Contact
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default BaseLayout;
