import { motion } from "framer-motion";
import { ArrowRight, BookOpen } from "lucide-react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../contexts/userContext";
import { Alert, AlertDescription } from "../ui/alert";
import { Button } from "../ui/button";
import { Input } from "../ui/input.jsx";
import { translateErrorMessage } from "../utils";
import GoogleSignupButton from "../auth/GoogleSignUpButton";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { login, googleLogin } = useUser();

  useEffect(() => {
    if (error.trim() !== "") {
      setError("");
    }
  }, [email, password]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const sessionExpiredParam = queryParams.get("sessionExpired");
    const showPromptParam = queryParams.get("showPrompt");
    if (sessionExpiredParam === "true" || showPromptParam === "true") {
      setError("Session expired. Please login.");
    }
  }, []);

  const handleLogin = async (e) => {
    localStorage.removeItem("userInfoCache");
    e.preventDefault();
    setIsLoading(true);
    try {
      await login(email, password);
      navigate("/homepage");
    } catch (error) {
      setError(translateErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };
  const handleGoogleLogin = async () => {
    try {
      setIsLoading(true);
      await googleLogin();
      navigate("/homepage");
    } catch (error) {
      if (error.code === "auth/popup-closed-by-user") {
        return;
      }
      if (error.code === "NO_ACCOUNT") {
        setError(
          "No account associated with that Google account. Please create an account"
        );
        return;
      }
      setError(translateErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-primary via-primary/80 to-primary/15 flex items-center justify-center px-4 sm:px-6 lg:px-8">
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-md w-full space-y-8 bg-white p-10 rounded-xl shadow-2xl"
      >
        <div>
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{
              delay: 0.2,
              type: "spring",
              stiffness: 260,
              damping: 20,
            }}
            className="mx-auto h-16 w-16 rounded-full flex items-center justify-center"
          >
            <img src="/favicon.png" className="h-10 w-14" />
            {/* <BookOpen className="h-8 w-8 text-primary-foreground" /> */}
          </motion.div>
          <h2 className="mt-2 text-center text-3xl font-extrabold text-foreground">
            Welcome to Aila Tutoring
          </h2>
          <p className="mt-2 text-center text-sm text-muted-foreground">
            Your personal AI tutor
          </p>
        </div>
        <div className="mt-6">
          <GoogleSignupButton
            onClick={handleGoogleLogin}
            isLoading={isLoading}
            text="Sign in with Google"
          />
          <div className="relative my-4">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white text-gray-500">Or</span>
            </div>
          </div>
        </div>

        <form className="mt-8 space-y-6" onSubmit={handleLogin}>
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <Input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-input placeholder-muted-foreground text-foreground rounded-t-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm"
                placeholder="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <Input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-input placeholder-muted-foreground text-foreground rounded-b-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                id="remember-me"
                name="remember-me"
                type="checkbox"
                className="h-4 w-4 text-primary focus:ring-primary/15 border-input rounded"
              />
              <label
                htmlFor="remember-me"
                className="ml-2 block text-sm text-foreground"
              >
                Remember me
              </label>
            </div>

            <div className="text-sm">
              <a
                href="#"
                className="font-medium text-primary hover:text-primary/80"
              >
                Forgot your password?
              </a>
            </div>
          </div>

          <div>
            <Button
              type="submit"
              disabled={isLoading}
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-primary-foreground bg-primary hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <BookOpen
                  className="h-5 w-5 text-primary-foreground group-hover:text-primary-foreground/90"
                  aria-hidden="true"
                />
              </span>
              {isLoading ? "Signing in..." : "Start Learning"}
            </Button>
          </div>
        </form>

        {error && (
          <Alert variant="destructive" className="text-center">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        <div className="text-center">
          <p className="mt-2 text-sm text-muted-foreground">
            New to Aila?{" "}
            <a
              href="/create-account"
              className="font-medium text-primary hover:text-primary/80"
            >
              Sign up now <ArrowRight className="inline-block h-4 w-4" />
            </a>
          </p>
        </div>

        <div className="flex justify-between mt-4">
          <a
            className="text-gray-600 text-sm hover:underline hover:cursor-pointer"
            onClick={() => navigate("/privacy-policy")}
          >
            Privacy Policy
          </a>
          <a
            href="mailto:jake@AilaEducation.com"
            className="text-gray-600 text-sm hover:underline"
          >
            Contact
          </a>
        </div>
        <div className="text-center text-xs text-gray-500">
          © {new Date().getFullYear()} Aila Education LLC. All rights reserved.
        </div>
      </motion.div>
    </div>
  );
};

export default LoginPage;
