import { useCallback, useEffect, useMemo, useState } from "react";
import api from "../api";

const CACHE_KEY = "userInfoCache";
const CACHE_EXPIRY = 5 * 60 * 1000; // 5 minutes

const useUserInfo = (user) => {
  const [userData, setUserData] = useState(() => {
    const cachedData = localStorage.getItem(CACHE_KEY);
    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData);
      if (Date.now() - timestamp < CACHE_EXPIRY) {
        return data;
      }
    }
    return {
      assignments: [],
      submissions: [],
    };
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const updateCache = useCallback((newData) => {
    setUserData(newData);
    localStorage.setItem(
      CACHE_KEY,
      JSON.stringify({
        data: newData,
        timestamp: Date.now(),
      })
    );
  }, []);

  const fetchUserData = useCallback(async () => {
    if (!user) return null;

    let submissions = [],
      assignments = [];

    const userPersonalAssignments = user.personalAssignments || [];

    if (userPersonalAssignments?.length > 0) {
      const assignmentsMap = userPersonalAssignments.map((id) =>
        api.get(`/personal-assignments/${id}`)
      );
      const promises = await Promise.all(assignmentsMap);
      const flatPromises = promises.flat();

      const assignmentData = flatPromises.map(
        (promise) => promise.data.assignment
      );
      assignments = assignmentData
        .filter((assignment) => assignment !== null)
        .sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate));

      const submissionPromises = assignments.map((assignment) =>
        api.get(`/submissions/${user.uid}_${assignment.id}`)
      );

      const submissionResponses = await Promise.all(submissionPromises);
      submissions = submissionResponses
        .map((response) => response.data)
        .filter(Boolean);
    }
    setLoading(false);
    return { assignments, submissions };
  }, [user]);

  const fetchData = useCallback(
    async (isBackgroundFetch = true) => {
      if (!user?.uid) return;
      if (!isBackgroundFetch) {
        setLoading(true);
      }
      setError(null);

      try {
        const fetchedData = await fetchUserData();
        if (fetchedData) {
          updateCache(fetchedData);
        }
      } catch (error) {
        console.error(`Error fetching ${user.role} data:`, error);
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [user?.uid, fetchUserData, updateCache]
  );

  const resetUserInfo = useCallback(() => {
    setUserData({
      assignments: [],
      submissions: [],
    });
    localStorage.removeItem(CACHE_KEY);
    setError(null);
  }, []);

  const updateAssignments = useCallback(
    (newAssignment) => {
      setUserData((prevData) => {
        const newData = {
          ...prevData,
          assignments: [...prevData.assignments, newAssignment],
        };
        updateCache(newData);
        return newData;
      });
    },
    [updateCache]
  );

  const removeAssignment = useCallback(
    (assignmentId) => {
      setUserData((prevData) => {
        const newData = {
          ...prevData,
          assignments: prevData.assignments.filter(
            (assignment) => assignment.id !== assignmentId
          ),
        };
        updateCache(newData);
        return newData;
      });
    },
    [updateCache]
  );

  const updateSubmissions = useCallback(
    (assignmentId, newAnswers) => {
      setUserData((prevUserData) => {
        const updatedSubmissions = prevUserData.submissions.map((submission) =>
          submission.assignmentId === assignmentId
            ? { ...submission, answers: newAnswers }
            : submission
        );
        const newData = {
          ...prevUserData,
          submissions: updatedSubmissions,
        };
        updateCache(newData);
        return newData;
      });
    },
    [updateCache]
  );

  useEffect(() => {
    if (user) {
      const cachedData = localStorage.getItem(CACHE_KEY);
      if (cachedData) {
        const { data, timestamp } = JSON.parse(cachedData);
        if (Date.now() - timestamp < CACHE_EXPIRY) {
          setUserData(data);
        } else {
          fetchData(false);
        }
      } else {
        fetchData(false);
      }
    }
  }, [user, fetchData]);

  const memoizedUserData = useMemo(() => userData, [userData]);

  return {
    ...memoizedUserData,
    loading,
    error,
    refreshUserInfo: (isBackgroundFetch = true) => fetchData(isBackgroundFetch),
    updateAssignments,
    removeAssignment,
    updateSubmissions,
    resetUserInfo,
  };
};

export default useUserInfo;
