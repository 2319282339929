import { Bot } from "lucide-react";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card.jsx";
import Switch from "../Switch";
import ChatComponent from "../ChatBoxComponents/ChatComponent";
import { useState, useRef } from "react";
import VoiceChat from "../ChatBoxComponents/VoiceChat";

const ChatCard = ({
  currentQuestion,
  chatLogId,
  saving,
  submitting,
  fileUrl,
}) => {
  const [isVoiceChat, setIsVoiceChat] = useState(false);
  const voiceChatRef = useRef(null);

  const handleChatToggle = async () => {
    if (isVoiceChat) {
      // If we're switching from voice to text, call cleanup first
      await voiceChatRef.current?.cleanup();
    }
    setIsVoiceChat(!isVoiceChat);
  };

  return (
    <Card className="w-1/3 bg-white shadow-sm h-full overflow-hidden">
      <CardHeader className="h-[10%]">
        <CardTitle className="flex items-center justify-between font-semibold text-primary">
          <span className="flex items-center gap-2">
            <Bot className="h-8 w-8" />
            Sparky
          </span>
          <div className="flex items-center space-x-2">
            <span className="text-sm mr-2">
              {isVoiceChat ? "Voice Chat" : "Text Chat"}
            </span>
            <Switch checked={isVoiceChat} onChange={handleChatToggle} />
          </div>
        </CardTitle>
      </CardHeader>

      <CardContent className="h-[90%] p-0">
        {isVoiceChat ? (
          <VoiceChat question={currentQuestion} ref={voiceChatRef} />
        ) : (
          <ChatComponent
            chatLogId={chatLogId}
            question={currentQuestion}
            exiting={saving || submitting}
            fileUrl={fileUrl}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default ChatCard;
