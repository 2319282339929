import { Button } from "./ui/button";
import { Edit, FileCheck, Loader2, Trash2 } from "lucide-react";
import { ScrollArea } from "./ui/scroll-area";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { useUser } from "./contexts/userContext";
import api from "./api";
import { useState } from "react";
import { ErrorAlert } from "./UniversalAlerts";

const AssignmentItem = ({ assignment, onClick, onDelete }) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async (e) => {
    e.stopPropagation();
    setIsDeleting(true);
    await onDelete(assignment.id);
  };

  return (
    <div className="flex justify-between group items-center hover:bg-primary/5 rounded-lg transition-colors">
      <Button
        variant="ghost"
        className="w-full justify-start text-left"
        onClick={onClick}
      >
        <div className="flex items-center w-full">
          <div className="bg-primary/10 p-2 rounded-full">
            <FileCheck className="h-4 w-4 text-primary" />
          </div>
          <div className="ml-4 flex-1">
            <h4 className="font-medium">{assignment.title}</h4>
            <p className="text-sm text-muted-foreground">
              Created {new Date(assignment.creationDate).toLocaleDateString()}
            </p>
          </div>
        </div>
      </Button>
      <div className="flex px-2">
        <Button
          variant="ghost"
          size="icon"
          className="opacity-0 group-hover:opacity-100 transition-opacity mr-2"
        >
          <Edit className="h-4 w-4" />
        </Button>
        <Button
          variant="ghost"
          size="icon"
          onClick={handleDelete}
          disabled={isDeleting}
          className={`opacity-0 group-hover:opacity-100 transition-opacity hover:bg-red-100 hover:text-red-600 ${
            isDeleting ? "opacity-50" : ""
          }`}
        >
          <Trash2 className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );
};

const AssignmentListContent = ({ assignments, onAssignmentClick, onError }) => {
  const { user, removeAssignment, refreshUserInfo } = useUser();
  const handleDelete = async (assignmentId) => {
    try {
      removeAssignment(assignmentId);

      await api.delete(
        `/users/${user.uid}/personal-assignment/${assignmentId}`
      );
      await api.delete(`/personal-assignments/${assignmentId}`);
    } catch (error) {
      onError("Failed to delete assignment. Please try again later.");
      refreshUserInfo();
    }
  };

  const sortedAssignments = assignments?.sort(
    (a, b) => new Date(b.creationDate) - new Date(a.creationDate)
  );

  return (
    <ScrollArea className="h-[500px]">
      {assignments?.length > 0 ? (
        <div className="space-y-2 pr-4">
          {sortedAssignments.map((assignment) => (
            <AssignmentItem
              key={assignment.id}
              assignment={assignment}
              onClick={() => onAssignmentClick(assignment.id)}
              onDelete={handleDelete}
            />
          ))}
        </div>
      ) : (
        <div className="text-center py-12 text-muted-foreground">
          No assignments yet. Create your first one!
        </div>
      )}
    </ScrollArea>
  );
};

const LoadingList = () => {
  return (
    <div className="flex tems-center justify-center h-[500px]">
      <Loader2 className="w-12 w-12 animate-spin" />
    </div>
  );
};

const AssignmentList = ({ assignments, navigate, loading }) => {
  const [error, setError] = useState("");

  const handleError = (errorMessage) => {
    setError(errorMessage);
    setTimeout(() => {
      setError("");
    }, 3000);
  };

  const clearError = () => {
    setError("");
  };

  return (
    <Card className="shadow-lg border-2 border-primary/10 hover:border-primary/20 transition-all duration-300">
      <CardHeader className="bg-primary/5 border-b border-primary/10">
        <CardTitle className="flex items-center text-2xl">
          <FileCheck className="mr-2 h-6 w-6" />
          Your Assignments
        </CardTitle>
      </CardHeader>
      <CardContent className="p-6">
        {error.trim() !== "" && <p className="text-red-600">{error}</p>}
        {loading ? (
          <LoadingList />
        ) : (
          <AssignmentListContent
            assignments={assignments}
            onAssignmentClick={(id) => navigate(`/assignment/${id}`)}
            onError={handleError}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default AssignmentList;
