import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { useUser } from "./contexts/userContext";
import LoadingPage from "./LoadingPage";
import DashboardHeader from "./DashboardHeader";
import GenerateAssignmentCard from "./GenerateAssignmentCard";
import AssignmentList from "./AssignmentList";
import BaseLayout from "./BaseLayout";

const Homepage = () => {
  const [isGenerating, setIsGenerating] = useState(false);
  const { assignments, loading, error, user } = useUser();
  const navigate = useNavigate();
  if (loading) return <LoadingPage />;

  return (
    <BaseLayout>
      <div className="min-h-screen bg-gray-50 p-8">
        <AnimatePresence mode="wait">
          {isGenerating ? (
            <motion.div
              key="generating"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="max-w-6xl mx-auto"
            >
              <motion.div
                layout
                className="max-w-2xl mx-auto"
                initial={{ scale: 0.95 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.3 }}
              >
                <GenerateAssignmentCard
                  isGenerating={isGenerating}
                  setIsGenerating={setIsGenerating}
                />
              </motion.div>
            </motion.div>
          ) : (
            <motion.div
              key="dashboard"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="max-w-6xl mx-auto space-y-8"
            >
              <DashboardHeader />
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 h-full">
                <motion.div layout className="h-full">
                  <GenerateAssignmentCard
                    isGenerating={isGenerating}
                    setIsGenerating={setIsGenerating}
                  />
                </motion.div>

                <AnimatePresence>
                  <motion.div
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 20 }}
                  >
                    <AssignmentList
                      assignments={assignments}
                      navigate={navigate}
                      loading={loading}
                    />
                  </motion.div>
                </AnimatePresence>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </BaseLayout>
  );
};

export default Homepage;
