import { UserPlus } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { useUser } from "../contexts/userContext.js";
import ValidationChecker from "./ValidationChecker.mjs";
import { Alert, AlertDescription } from "../ui/alert";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import GoogleSignupButton from "../auth/GoogleSignUpButton.js";

// Form field component to reduce repetition
const FormField = ({
  label,
  id,
  type = "text",
  value,
  onChange,
  placeholder,
}) => (
  <div className="space-y-2">
    <Label htmlFor={id}>{label}</Label>
    <Input
      id={id}
      name={id}
      type={type}
      required
      className="w-full"
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  </div>
);

// Role selector component
const RoleSelector = ({ role, setRole }) => (
  <div className="space-y-4">
    <div>
      <Label htmlFor="role">I am a...</Label>
      <Select onValueChange={setRole} value={role}>
        <SelectTrigger className="w-full">
          <SelectValue placeholder="Select your role" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="student">Student</SelectItem>
          <SelectItem value="teacher">Teacher</SelectItem>
        </SelectContent>
      </Select>
    </div>
  </div>
);

// Google sign-up section
const GoogleSignUpSection = ({ onGoogleSignup, isLoading }) => (
  <div className="mt-6">
    <GoogleSignupButton onClick={onGoogleSignup} isLoading={isLoading} />
    <div className="relative my-4">
      <div className="absolute inset-0 flex items-center">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="relative flex justify-center text-sm">
        <span className="px-2 bg-white text-gray-500">Or</span>
      </div>
    </div>
  </div>
);

// Main signup form
const SignupForm = ({ formData, setFormData, onSubmit, isLoading }) => {
  const { firstName, lastName, email, password, confirmPassword } = formData;

  return (
    <form className="mt-8 space-y-6" onSubmit={onSubmit}>
      <div className="space-y-4">
        <div className="flex gap-4">
          <div className="flex-1">
            <FormField
              label="First name"
              id="first_name"
              value={firstName}
              onChange={(value) =>
                setFormData((prev) => ({ ...prev, firstName: value }))
              }
              placeholder="First name"
            />
          </div>
          <div className="flex-1">
            <FormField
              label="Last name"
              id="last_name"
              value={lastName}
              onChange={(value) =>
                setFormData((prev) => ({ ...prev, lastName: value }))
              }
              placeholder="Last name"
            />
          </div>
        </div>

        <FormField
          label="Email address"
          id="email"
          type="email"
          value={email}
          onChange={(value) =>
            setFormData((prev) => ({ ...prev, email: value }))
          }
          placeholder="Email address"
        />

        <FormField
          label="Password"
          id="password"
          type="password"
          value={password}
          onChange={(value) =>
            setFormData((prev) => ({ ...prev, password: value }))
          }
          placeholder="Password"
        />

        <FormField
          label="Confirm Password"
          id="confirmPassword"
          type="password"
          value={confirmPassword}
          onChange={(value) =>
            setFormData((prev) => ({ ...prev, confirmPassword: value }))
          }
          placeholder="Confirm Password"
        />
      </div>

      <Button
        type="submit"
        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
        disabled={isLoading}
      >
        <span className="absolute left-0 inset-y-0 flex items-center pl-3">
          <UserPlus
            className="h-5 w-5 text-white group-hover:text-primary-foreground/90"
            aria-hidden="true"
          />
        </span>
        {isLoading ? "Creating your account..." : "Create Account"}
      </Button>
      <p className="text-gray-600 text-sm text-center">
        By signing up, you confirm you are at least 13 years old.
      </p>
    </form>
  );
};

// Main component
const CreateAccountPage = () => {
  const navigate = useNavigate();
  const { signup, googleSignup } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [role, setRole] = useState("");
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const validateForm = () => {
    const validationErrors = {};
    const { firstName, lastName, email, password, confirmPassword } = formData;

    if (!ValidationChecker.isValidName(firstName)) {
      validationErrors.firstName = "Please enter a valid first name.";
    }
    if (!ValidationChecker.isValidName(lastName)) {
      validationErrors.lastName = "Please enter a valid last name.";
    }
    if (!ValidationChecker.isValidEmail(email)) {
      validationErrors.email = "Please enter a valid email address.";
    }
    if (!ValidationChecker.isValidPassword(password)) {
      validationErrors.password =
        "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, and one digit.";
    }
    if (password !== confirmPassword) {
      validationErrors.confirmPassword = "Passwords do not match.";
    }

    return validationErrors;
  };

  const handleCreateAccount = async (e) => {
    e.preventDefault();
    localStorage.removeItem("userInfoCache");

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      setIsLoading(true);
      const { firstName, lastName, email, password } = formData;
      await signup(firstName, lastName, email, password, role);
      navigate("/homepage");
    } catch (error) {
      setErrors({
        general:
          error.message ||
          "An error occurred while creating the account. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleSignup = async () => {
    localStorage.removeItem("userInfoCache");
    try {
      setIsLoading(true);
      await googleSignup(role);
      navigate("/homepage");
    } catch (error) {
      if (error.code === "auth/popup-closed-by-user") {
        setErrors({});
        return;
      }
      setErrors({
        general:
          error.message ||
          "An error occurred during Google sign up. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-primary via-primary/80 to-primary/15 flex items-center justify-center px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-white p-10 rounded-xl shadow-2xl">
        {/* Header */}
        <div>
          <div className="mx-auto h-16 w-16 bg-primary rounded-full flex items-center justify-center">
            <UserPlus className="h-8 w-8 text-white" />
          </div>
          <h2 className="mt-2 text-center text-3xl font-extrabold text-foreground">
            Welcome to Aila Tutoring
          </h2>
          <p className="mt-2 text-center text-sm text-muted-foreground">
            Your personal tutor for any assignment
          </p>
        </div>

        {/* Main content */}
        <AnimatePresence mode="wait">
          {!role ? (
            <motion.div
              key="role-selector"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              <RoleSelector role={role} setRole={setRole} />
            </motion.div>
          ) : (
            <motion.div
              key="signup-form"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              <GoogleSignUpSection
                onGoogleSignup={handleGoogleSignup}
                isLoading={isLoading}
              />
              <SignupForm
                formData={formData}
                setFormData={setFormData}
                onSubmit={handleCreateAccount}
                isLoading={isLoading}
              />
            </motion.div>
          )}
        </AnimatePresence>

        {/* Error messages */}
        {Object.keys(errors).map((key) => (
          <Alert key={key} variant="destructive">
            <AlertDescription>{errors[key]}</AlertDescription>
          </Alert>
        ))}

        {/* Footer */}
        <div className="flex flex-col space-y-6">
          <div className="flex flex-col items-center space-y-4">
            <p className="text-sm text-gray-600">
              Already have an account?{" "}
              <a
                href="/login"
                className="font-medium text-primary hover:text-primary/80"
              >
                Sign in
              </a>
            </p>
          </div>

          <div className="flex justify-between mt-4">
            <a
              className="text-gray-600 text-sm hover:underline hover:cursor-pointer"
              onClick={() => navigate("/privacy-policy")}
            >
              Privacy Policy
            </a>
            <a
              href="mailto:jake@AilaEducation.com"
              className="text-gray-600 text-sm hover:underline"
            >
              Contact
            </a>
          </div>
          <div className="text-center text-xs text-gray-500">
            © {new Date().getFullYear()} Aila Education LLC. All rights
            reserved.
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAccountPage;
