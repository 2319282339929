import React from "react";
import { Textarea } from "./ui/textarea.jsx";
import { Label } from "./ui/label.jsx";

const Text = ({ text, onChange }) => {
  return (
    <div>
      <Label htmlFor="text">Question Text:</Label>
      <Textarea
        type="text"
        id="text"
        value={text}
        placeholder="Enter the question"
        onChange={onChange}
      />
    </div>
  );
};

export default Text;
