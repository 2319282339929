import { Textarea } from "../ui/textarea.jsx";
import { Button } from "../ui/button.jsx";

const ChatInput = ({ message, setMessage, handleChat, chatDisabled }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    if (message.trim()) {
      handleChat();
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      handleSubmit(e);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="border-t bg-white p-4">
      <div className="flex items-center space-x-2">
        <Textarea
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Enter your message"
          className="flex-grow resize-none"
          maxLength={350}
          disabled={chatDisabled}
        />
        <Button
          type="submit"
          variant="default"
          size="default"
          disabled={message.length === 0 || chatDisabled}
        >
          Send Message
        </Button>
      </div>
    </form>
  );
};

export default ChatInput;
