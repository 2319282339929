import React, { createContext, useContext, useState, useEffect } from "react";
import api from "../api";
import { generateTimestamp } from "../utils/Timestamp";

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [soundPlaying, setSoundPlaying] = useState(false);
  const [chatLogs, setChatLogs] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentChatLogId, setCurrentChatLogId] = useState(null);

  useEffect(() => {
    if (currentChatLogId) {
      const fetchChatLogs = async () => {
        try {
          setLoading(true);
          setError(null);
          const chatLogResponse = await api.get(
            `/chatLogs/${currentChatLogId}`
          );
          const fetchedLogs = chatLogResponse.data.questions || {};
          setChatLogs(fetchedLogs);
        } catch (error) {
          setError("Failed to fetch chat logs. Please try again.");
        } finally {
          setLoading(false);
        }
      };
      fetchChatLogs();
    }
  }, [currentChatLogId]);

  const updateChatLog = (questionId, type, message) => {
    setChatLogs((prevChatLogs) => ({
      ...prevChatLogs,
      [questionId]: [
        ...(prevChatLogs[questionId] || []),
        { type: type, chat: message, timestamp: generateTimestamp() },
      ],
    }));
  };

  const saveLogs = async () => {
    if (!currentChatLogId) return;

    const stringifiedLogs = JSON.stringify(chatLogs);
    try {
      await api.post("/chatLogs", {
        chatLogs: stringifiedLogs,
        logId: currentChatLogId,
      });
    } catch (error) {
      setError("Failed to save logs.");
    }
  };

  const initializeChatLog = (chatLogId) => {
    setCurrentChatLogId(chatLogId);
  };

  const handleError = (errorMessage) => {
    setError(errorMessage);
    setTimeout(() => setError(null), 3000);
  };

  return (
    <ChatContext.Provider
      value={{
        soundPlaying,
        setSoundPlaying,
        chatLogs,
        updateChatLog,
        loading,
        error,
        handleError,
        saveLogs,
        initializeChatLog,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChatMessages = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error("useChatMessages must be used within a ChatProvider");
  }
  return context;
};

export default ChatContext;
