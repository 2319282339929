import { Card, CardHeader, CardTitle } from "../ui/card.jsx";

const QuestionHeader = ({ question }) => {

  return (
    <Card className="mb-6">
      <CardHeader className="relative">
        <CardTitle className="text-xl font-semibold text-primary">
          {question.text}
        </CardTitle>
      </CardHeader>
    </Card>
  );
};

export default QuestionHeader;
