import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card.jsx";
import QuestionNavigation from "./QuestionNavigator.js";
import QuestionRenderer from "./QuestionRenderer.js";
import { Badge } from "../ui/badge.jsx";

const QuestionCard = ({
  currentQuestion,
  currentIndex,
  questionsLength,
  answers,
  setAnswers,
  assignmentId,
  userId,
  handleNextQuestion,
  handlePreviousQuestion,
}) => {
  const { type } = currentQuestion;
  const questionType = type ? getQuestionType(type) : null;

  return (
    <Card className="w-2/3 flex flex-col h-[800px] bg-white shadow-sm">
      <CardHeader>
        <CardTitle className="text-xl font-semibold text-gray-900 flex items-center justify-between">
          Question {currentIndex + 1}
          {questionType && (
            <Badge
              variant="secondary"
              className="text-xs font-semibold bg-secondary text-secondary-foreground"
            >
              {questionType}
            </Badge>
          )}
        </CardTitle>
      </CardHeader>
      <CardContent className="flex-grow overflow-y-auto">
        <QuestionRenderer
          question={currentQuestion}
          questionIndex={currentIndex}
          answers={answers}
          setAnswers={setAnswers}
          assignmentId={assignmentId}
          studentId={userId}
        />
      </CardContent>
      <CardFooter className="mt-auto">
        <QuestionNavigation
          currentQuestionIndex={currentIndex}
          questionCount={questionsLength}
          onNext={handleNextQuestion}
          onPrevious={handlePreviousQuestion}
        />
      </CardFooter>
    </Card>
  );
};

const getQuestionType = (type) => {
  if (type === "multiple-choice") {
    return "Multiple Choice";
  } else if (type === "short-answer") {
    return "Short Answer";
  } else if (type === "select-all") {
    return "Select All";
  } else if (type === "essay") {
    return "Essay";
  } else if (type === "Coding") {
    return "Coding";
  } else if (type === "file-upload") {
    return "File Upload";
  } else {
    return null;
  }
};

export default QuestionCard;
